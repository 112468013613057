import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-careers',
    templateUrl: './careers.component.html',
    styleUrls: ['../../../../app.common.scss', './careers.component.scss']
})
export class CareersComponent {

    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Careers", "Explore exciting career opportunities at Genailia. Join our team and contribute to innovation in AI, NLP, and language services.", "about us, all about genailia, team, careers, partnerships, our thesis, vision, mission, people, company, investors, associations", "careers");
    }

    constructor(
        private myservice: MyService,
    ) {
    }

}
