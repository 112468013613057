import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  socket: any;

  constructor() {
    this.setUpSocketConnection();
  }

  setUpSocketConnection() {
    console.log('connection establishment');
    this.socket = io('https://signal.genailia.com');
  }
}
