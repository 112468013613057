import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-thesis',
    templateUrl: './thesis.component.html',
    styleUrls: ['../../../../app.common.scss', './thesis.component.scss']
})
export class ThesisComponent {

    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Thesis","Genailia envisions an AI-driven future, empowering businesses with user-friendly tools to unlock creativity and efficiency through democratized AI","about us, all about genailia, team, careers, partnerships, our thesis, vision, mission, people, company, investors, associations","thesis");
    }

    constructor(
        private myservice: MyService,
    ) {
    }

}
