import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyService } from 'src/app/services/myservice';

@Component({
    selector: 'app-get-demo',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './get-demo.component.html',
    styleUrls: ['./get-demo.component.scss']
})
export class GetDemoComponent implements OnInit {

    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Get a Demo", "Get a Demo of Genailia's products - ellmo, genailia, Lingo etc.", "get demo, about us, all about genailia, team, careers, partnerships, our thesis, vision, mission, people, company, investors, associations", "get-demo");
    }

    constructor(
        private myservice: MyService,
    ) {
    }
}
