<div class="glossary jumbotron jumbotron-fluid  text-center">
  <div class=" glossary-heading">
    <h1 class="display-1">Glossary</h1>
  </div>
</div>
<div class="container">
    
    <div class="container d-flex justify-content-center  list-group " >

        <a [routerLink]="['/glossary',item._id]" class="list-group-item list-group-item-action flex-column align-items-start" *ngFor= "let item of glossaryItems">
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">{{item.keyword}}</h5>
          </div>
          <p class="mb-1">{{item.description}}</p>
        </a>

    </div>
</div>