import { Location } from '@angular/common';
import { SwUpdate } from '@angular/service-worker';
import {
    Component,
    ElementRef,
    ViewChild,
    Inject,
    PLATFORM_ID
} from '@angular/core';
import { Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { AuthService } from './shared/services/auth.service';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as AOS from 'aos';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    currentPath!: String;
    idleState = 'Not started.';
    timedOut = false;
    lastPing!: Date;

    @ViewChild('idleModal', { static: false }) idleModal!: ElementRef;

    constructor(private swUpdate: SwUpdate,
        private idle: Idle,
        location: Location,
        router: Router,
        private keepalive: Keepalive,
        private authService: AuthService,
        config: NgbModalConfig,
        private modalService: NgbModal,
    ) {
        config.backdrop = 'static';
        config.keyboard = false;

        idle.setIdle(1800);
        idle.setTimeout(30);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        idle.onIdleEnd.subscribe(() => {
            this.reset();
        });

        idle.onTimeout.subscribe(() => {
            this.modalService.dismissAll();
            this.idleState = 'Timed out!';
            this.timedOut = true;
            if (this.authService.isUserLoggedIn()) {
                this.authService.logout();
            }
        });

        idle.onIdleStart.subscribe(() => {
            this.modalService.dismissAll();
            this.idleState = "Idle Session";
            if (this.authService.isUserLoggedIn()) {
                this.modalService.open(this.idleModal);
                var audio = new Audio();
                audio.src = "../assets/sounds/notify.mp3";
                audio.load();
                audio.play();
            }
        });

        idle.onTimeoutWarning.subscribe((countdown) => {
            this.idleState = 'Session will time out in ' + countdown + ' seconds.';
        });

        keepalive.interval(15);
        keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

        router.events.subscribe((val) => {
            this.currentPath = location.path();
            if (this.currentPath.search(/login/gi) == -1) idle.watch();
            else idle.stop();
        });
    }


    reset() {
        this.idle.watch();
        this.idleState = 'Your session has been idle for 30 minutes. Would you like to continue?';
        this.timedOut = false;
    }

    logout() {
        this.modalService.dismissAll();
        this.authService.logout();
    }

    stay() {
        this.modalService.dismissAll();
        this.reset();
    }

    ngOnInit() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm("A newer version of this page is available. Do you want to load it?")) {
                    window.location.reload();
                }
            });
        }
        
        //Animate on scroll
        window.addEventListener('load', () => {
            AOS.init({
                duration: 500,
                offset: 0,
                once: true,

            });
        });
    }

}
