import { Component, Input } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FAQS, FAQS_NEW } from 'src/app/shared/constants/home';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-faq-copy',
    templateUrl: './faqcopy.component.html',
    styleUrls: ['../../../../app.common.scss', './faqcopy.component.scss'],
})
export class FaqCopyComponent {
    constructor(deviceService: DeviceDetectorService,
        private myservice: MyService
    ) {
        if (deviceService.isMobile()) {
            this.anchorPlacement = 'top-bottom';
            this.responsiveFade = 'fade-up';
        }


    }

    pricing = [
        {
            id: 1,
            question: 'What Pricing Models do you support?',
            answer: `To know the pricing models that we support, please refer to the <b><a href="/pricing">'Pricing'</a></b> menu from the Menu bar at the top of this web page.`,
        },
        {
            id: 2,
            question: `Do you provide 24x7 support for the 'Free' pricing model?`,
            answer: `The 'Free' model provides very basic support with no time frames. Please reach out via the <b><a href="/contact">'Contact'</a></b> section.`,
        },
    ];

    languages = [
        {
            id: 1,
            question: 'How many languages do you support?',
            answer: `<p>We support almost 100 language on our platform</p>
      `,
        },
        {
            id: 2,
            question: `How accurate are the translations?`,
            answer: `Machine translations are never 100% accurate. In terms of the accuracy, it depends on the domain and the language itself. Please contact us for more information.`,
        },
    ];

    general = [
        {
            id: 1,
            question: 'Tell us a little about GenZ Technologies.',
            answer: `GenZ Technologies (Genailia.com | GenZers.AI | GenZers.Tech) is a <b>Startup India</b> recognized company, founded in October 2021`,
        },
        {
            id: 2,
            question: 'Where do you operate from?',
            answer: `Operating out of <b>T-Hub</b>, Hyderabad`,
        },
        {
            id: 3,
            question: 'What services do you provide?',
            answer: `We provide affordable <b>AI Products &amp; Services</b> for our clients and partners. Build and deploy quality AI products in a <b>PaaS</b> model. All our products are also available via Restful APIs`,
        },

        {
            id: 7,
            question: 'Do you offer REST APIs?',
            answer: `All our products are REST API enabled, ready to be consumed. We top it up with JWT security and nice documentation`,
        },
        {
            id: 9,
            question: 'What Products do you offer?',
            answer: `Speech to Text (STS), Text to Speech (TTS), Emotional TTS, Translation, Transcription, Transliteration, Chatbots, GPT, Private GPT, LLM, Document Translation`,
        },
        {
            id: 10,
            question:
                'What services do you offer? Can you Tailor fit custom AI components?',
            answer: `All aspects of AI/ML, Public/Private Cloud Onboarding/Deployments. We do support Custom Software Development`,
        },
        {
            id: 11,
            question: 'Where can you deploy products that you custom fit?',
            answer: `On-Prem or Public Cloud - AWS/Azure/GCP`,
        },
        {
            id: 12,
            question:
                'I have a suggestion or two for improvement. How do I reach out?',
            answer: `We are always looking for ways to improve our site and provide the best for our customers. Please reach out to us through the Contact Us section.`,
        },
    ];

    ai = [
        {
            id: 1,
            question: 'Which AI model do you use for translation?',
            answer: `We use Deep Learning to generate our translations. In the world of 'Translation', this is state of the art!`,
        },
        {
            id: 2,
            question:
                'I have a suggestion or two for improvement. How do I reach out?',
            answer: `We are always looking for ways to improve our site and provide the best for our customers. Please reach out to us through the Contact Us section.`,
        },
    ];
    active = 3;

    responsiveFade = 'fade-left';
    anchorPlacement = 'bottom-bottom';
    activeNav = 1;

    faqs = FAQS;
    canonicalUrl: any;
    ngOnInit(): void {
        this.myservice.setTitleAndMeta("FAQ - Frequently Asked Questions", "FAQ - Frequently Asked Questions about Genailia - Find answers to common questions about Genailia's products, services, and policies.", "FAQ, frequent questions", "faq");
    }
}
