import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
    
  constructor(private myservice:MyService) {
      this.myservice.setTitleAndMeta("Privacy Policy","Genailia's Privacy Policy","terms and conditions, privacy poicy, payment terms, return policy, refund policy, T&C, cookie policy, GDPR, data collection, cookies, storage policy, EULA, legal aspects, law","privacy-policy");
  }

}
