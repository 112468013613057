import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';

@Component({
    selector: 'app-ai-transformation',
    templateUrl: './ai-transformation.component.html',
    styleUrls: ['../../../../app.common.scss', './ai-transformation.component.scss']
})
export class AiTransformationComponent {
    constructor(private myservice: MyService) {
        this.myservice.setTitleAndMeta("AI Transformations", "AI Transformations - Our Capabilities = Your Transformations", "ai transformations, capabilities", "ai-transformation");
    }
}
