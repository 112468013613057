import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-leadership',
    templateUrl: './leadership.component.html',
    styleUrls: ['../../../../app.common.scss', './leadership.component.scss']
})
export class LeadershipComponent {

    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Our Leadership","Meet the leaders at Genailia who drive innovation in AI, NLP & language services.Learn about our vision & the team behind our cutting-edge solutions.","about us, all about genailia, team, careers, partnerships, our thesis, vision, mission, people, company, investors, associations","leadership");
    }

    constructor(
        private myservice: MyService
    ) {
    }

}
