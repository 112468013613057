import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GlossaryDataService } from '../glossary-data.service';
import { MyService } from 'src/app/services/myservice';

@Component({
    selector: 'app-glossary',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './glossary.component.html',
    styleUrls: ['./glossary.component.scss']
})
export class GlossaryComponent {
    glossaryItems: any[] = []
    glossaryService: any = inject(GlossaryDataService)

    constructor(
        private myservice: MyService,
    ) {
        this.glossaryItems = this.glossaryService.getAllkeywords()
    }
    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Glossary", "The Genailia Glossary provides definitions of key terms related to AI, machine learning & NLP, helping users to better understand the technology.", "AI Ethics,Artificial Neural Network,Unsupervised Learning,Reinforcement Learning,Computer Vision,Natural Language Processing,Deep Learning,Neural Networks,Machine Learning,Glossary,AI Terminology, Industry, best practices", "glossary");
    }



}
