<div class="container py-4 payment">
	<div>
		<h1 class="payment-heading-text">Terms of Payment</h1>
	</div>

				<h2 class="payment-subheading-text">v1.1 - Last Edited 19th August
					2024</h2>
	<div class="d-flex flex-column-reverse flex-lg-row">
		<div>
			<div class="payment-content">
				<p>Our Terms of Payment page outlines the financial commitments
					associated with using our SaaS platform and our on-prem platform.
					Here, you'll find details about billing cycles, acceptable payment
					methods, refund procedures, and any associated fees. We encourage
					you to review these terms to fully understand the financial aspects
					of our business partnership.</p>
			</div>

			<div class="payment-content">
				<h2 class="payment-subheading-text">1. GENERAL TERMS</h2>
				<p>
					1.1. These Payment Terms are in addition to the Terms of Use
					available at <a class="underline"
						href="https://www.genailia.com/terms-and-conditions">https://www.genailia.com/terms-and-conditions</a>.
					All capitalized terms used herein and not defined shall have the
					same meaning as ascribed in the Terms of Use.
				</p>
				<p>1.2. Kindly note that these Payment Terms may be subject to
					change from time to time pursuant to our business needs, legal
					compliance requirements, or any other reason we deem necessary. The
					most current version of these Payment Terms will supersede all
					previous versions. Therefore, it is advised that you read the most
					updated version of these Payment Terms before completing any
					commercial transaction with the Company.</p>
			</div>

			<div class="payment-content">
				<h2 class="payment-subheading-text">2. SUBSCRIPTION MODELS</h2>
				<p>2.1. To avail of the Service on the Platform, you must create
					an account on our Platform. While creating an account, you will
					have to choose one of the three (3) subscription models offered by
					us for our Services, detailed below:</p>
				<ul>
                    <li>Starter</li>
                    <li>Creator</li>
					<li>Enterprise</li>
					<li>Pay as you go</li>
				</ul>
				<p>
					2.2. Some of the key features of the Services provided by us under
					each of the above-mentioned models can be viewed at <a
						href="https://www.genailia.com/home">https://www.genailia.com/home</a>.
				</p>
				<p>2.3. In addition to the key features referred to in clause
					2.2 of these Payment Terms:</p>
				<p>a. Under the Starter, Creator, and Pay-as-you-go plans, we provide you with
					access to the Platform through the Company's hosting server, which
					may be operated and managed by the Company or through a third-party
					vendor ("Company Hosting Server"); and</p>
				<p>b. Under the Enterprise Model, we provide you with access to
					the Platform through the User's hosting server, which may be
					operated and managed by the User or through a third-party vendor
					("User Hosting Server").</p>
				<p>2.4. Your use of the Platform and/or Services depends on the
					proper functioning of the Company Hosting Server or User Hosting
					Server, as the case may be. The Company takes no responsibility for
					any defect, discrepancy, downtime, bug, error, or other inaccuracy
					in rendering the Platform and/or Services that are not directly,
					proximately, and solely attributable to the improper functioning of
					the Platform and/or Services when properly set up and used in a
					defined and approved computing environment.</p>
				<p>2.5. We shall not be responsible for rectifying any problems
					and issues related to your use of the Platform and/or Services or
					with the functioning of the Project that you have developed on the
					Platform and/or Services, which are attributable to or related to
					the improper functioning of the User Hosting Server.</p>
			</div>

			<div class="payment-content">
				<h2 class="payment-subheading-text">3. FEES FOR OUR SERVICES</h2>
				<p>3.1. You acknowledge that, in addition to the payment of fees
					for access to the Platform and/or our Services, you shall be liable
					to make necessary payments to third-party service providers for the
					use of their services, including without limitation, the access and
					use of their Third-Party Application. The mode and structure of
					payment to such third-party service providers will be governed by
					their respective Third-Party Terms. You further acknowledge that we
					neither control nor determine or advise or in any way involve
					ourselves in the offering or acceptance of such
					commercial/contractual terms between Users and third-party service
					providers. Further, we shall not be responsible or held liable in
					any manner whatsoever for (i) any improper functioning of our
					Services due to your non-payment of fees and/or any dues to
					third-party service providers for the use of their services,
					including without limitation, the access and use of their
					Third-Party Application; and (ii) any unforeseen event that may
					occur due to such non-payment of fees and/or dues.</p>
				<p>3.2. You will be liable to pay such fees as shall be
					communicated by the Company to you directly or by way of
					subscription in a manner provided on the Platform for the use of
					our Services. All payment obligations for the Services shall be
					completed and fulfilled by you promptly within the stipulated time
					period provided by us, if any.</p>
				<p>3.3. You agree and acknowledge that the fees and amounts
					payable for the usage of the Platform and for the procurement of
					the Services under these Payment Terms are non-refundable in
					nature. The fees or other costs or amounts payable under these
					Payment Terms are subject to changes based on the Services availed.
					The Company shall not be responsible in any manner for such changes
					in the costs of the subscription plan chosen.</p>
				<p>3.4. You agree that the payments made to the Company and
					received by the Company may differ based on geo-locations, and such
					payments shall be received as per the local currency of the various
					geo-locations.</p>
				<p>3.5. You understand that there may be certain taxes and
					statutory charges applicable with respect to our Services in the
					jurisdiction from where you access them. The fees are exclusive of
					all such applicable taxes and statutory charges. Therefore, you
					shall also be responsible for the payment of such applicable taxes
					and statutory charges separately. Additionally, you agree that you
					will be responsible for any credit card chargeback fees as well as
					any reasonable collection costs that we incur as a result of your
					failure to pay on a timely basis.</p>
				<p>3.6. Payments may be made by you via credit card, debit card,
					net banking facility, UPI, wallets, or any other means available.
					The Company shall routinely start billing you through the payment
					method chosen by you at the time of registration/creation of your
					Account.</p>
				<p>3.7. You understand, accept, and agree that the payment
					facility provided by the Company is neither a banking nor financial
					service but merely a facilitator providing an electronic, automated
					online payment, receiving payment through collection and remittance
					facility for the transactions on the Platform using the existing
					authorized banking infrastructure and credit card payment gateway
					networks.</p>
				<p>3.8. While availing any of the payment methods available on
					the Platform, the Company will not be responsible or assume any
					liability in any manner whatsoever, including for errors, delays,
					erroneous transactions, or any loss or damage arising directly or
					indirectly to you. No liability will be accepted by the Company if
					the payment is refused or declined by the bank/credit card
					supplier/debit card supplier/UPI wallet for any reason whatsoever.</p>
				<p>3.9. Any complaints with respect to the payment may be made
					by you directly to your bank or the payment gateway.</p>
				<p>3.10. If the payment has not been made, it is not the
					obligation of the Company to bring the same to your attention. You
					should check with your bank/credit card supplier/debit card
					supplier/UPI wallet that the payment has been deducted from your
					account. However, if the payment has not been made, you should
					contact your payment provider. All payment details entered through
					the payment gateway are encrypted when you make the payment.</p>
				<p>3.11. If you use any links for making payments from various
					geo-locations, you shall be responsible for the same, and it shall
					be at your sole risk. The Company shall in no manner be held
					responsible for the same.</p>
				<p>3.12. In case of failure by the User to make payment of fees
					or to properly protect the data provided during the online payment
					process, or in respect of any omission to provide accurate
					information in the course of online payment, GenZ Technologies
					shall not be held liable.</p>
				<p>3.13. The Company uses the utmost care to ensure that all
					data/information in respect of the electronic transfer of money
					available to their payment aggregators and partners does not fall
					into the wrong hands.</p>
				<p>3.14. The payment method can be changed by you during the
					tenure of your subscription. However, if the change in the payment
					method is not successful and the payment does not go through, you
					shall be liable to make such payments, and until then, your access
					to the Services shall stand suspended. The Company shall in no
					manner whatsoever be responsible for the same.</p>
				<p>3.15. The amount shall be billed to you at the beginning of
					the tenure, and such amounts shall be payable for the Starter and
					Creator Models on monthly/yearly intervals, and for the Enterprise
					Model on regular intervals as agreed between the Company and the
					User.</p>
				<p>3.16. You shall be solely responsible for the information
					provided with respect to the payment methods. You agree that the
					Company shall not be liable for any losses, either direct,
					indirect, incidental, or consequential, in relation to any delay in
					processing the payment made by you.</p>
				<p>3.17. The Company reserves the right to make any amendments,
					modifications, or terminate any payment plans at any time, at its
					sole discretion. You shall be subjected to such changes and must
					ensure to review the Payment Terms and Terms of Use from time to
					time to stay aware of any such changes and modifications. Your
					non-awareness of the same does not make the Company responsible in
					any manner.</p>
				<p>3.18. If you wish to cancel your subscription, you may do so
					using the Platform. In the event of cancellation prior to the
					expiration period, you will not be entitled to any refund for any
					portion of the fee already paid. After cancellation, if you wish to
					re-subscribe, you may do so through the Platform.</p>
			</div>

			<div class="payment-content">
				<h2 class="payment-subheading-text">4. AUTORENEWAL</h2>
				<p>4.1. We accept certain debit and credit cards as payment
					methods for Services (each, an "Accepted Payment Method"), subject
					to the procedures and rules contained in these Payment Terms and
					any applicable contract with the Accepted Payment Method. Accepted
					Payment Methods may vary depending on the Services opted by you and
					may change from time to time. When you purchase with an Accepted
					Payment Method, the fees for your Services will be billed on or
					near the date of your purchase.</p>
				<p>4.2. If you purchase a Service that renews automatically,
					such as monthly or annually, you agree that we may process your
					Accepted Payment Method on each monthly, annual, or other renewal
					term (based on the applicable billing cycle), on or near the
					calendar day corresponding to the commencement of your Service
					subscription, as applicable. In addition, you agree that we have
					permission to retain and/or share with financial institutions and
					payment processing firms (including any institutions or firms we
					retain in the future) information regarding your purchase and your
					submitted payment information to process your purchase and to use
					the email address submitted by you to provide you with notices and
					disclosures relating to renewals and recurring charges.</p>
			</div>

			<div class="payment-content">
				<h2 class="payment-subheading-text">5. CHANGES TO PAYMENT METHODS
					AND ACCOUNT INFORMATION</h2>
				<p>5.1. You shall be responsible for ensuring that your account
					information, including your email address and all payment
					information (address, debit or credit card number, and expiration
					date), is accurate and up to date. You may change this information
					at any time and, upon any such material change, you shall be
					responsible for informing us at the earliest possible time. If
					under any circumstance, you fail to provide us with information
					regarding the material change within a reasonable time period, then
					you shall solely be liable for any and all liability the Company or
					you may face due to your failure to fulfill your obligation.</p>
				<p>5.2. If your credit card expires, the information you
					provided to us is otherwise invalid, or you make any changes to
					your billing account too close to when we charge for any Service
					(so we cannot reasonably act on your changes prior to billing),
					then you understand that we may not be aware of this and will bill
					the current charges to the card we have on record. The continuation
					of our Services constitutes your authorization for us to do so, and
					you remain responsible for any uncollected amounts and any charges
					incurred by you or us as a result of billing to an invalid card.</p>
			</div>

			<div class="payment-content">
				<h2 class="payment-subheading-text">6. COSTS OF COLLECTION; CREDIT
					CARD CHARGEBACKS</h2>
				<p>6.1. You agree to pay any reasonable costs that we incur to
					collect any unpaid or past-due amounts, including reasonable
					attorneys' fees and other associated costs. Delayed payments may,
					at our discretion, bear interest at the rate of 14% per annum or
					the highest rate permitted under applicable law, whichever is less,
					from the payment due date until paid in full.</p>
				<p>6.2. If you purchase a Service with a credit card and
					subsequently request your credit card issuer to reverse that
					payment, and we are charged a fee that may be a significant
					multiple of the amount initially received, you acknowledge and
					agree that we reserve the right to suspend your access to your
					Account until such time as you reimburse us for the fee charged by
					the card issuer.</p>
			</div>
		</div>
	</div>
</div>
