import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

	/*
    if (!this.authService.isUserLoggedIn()) {
      this.router.navigate(['login'], { queryParams: { 'redirectURL': state.url } });
      return false;
    } else {
      return true;
    }
    */
    return true; //TODO: Temporarily turning off security on the webpage
  }

}
