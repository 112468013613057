export const SERVICE_CARDS = [
    {
        iconClass: 'service-box_icon fa-solid fa-robot add2',
        title: 'Conversational AI',
        description:
            'Empowering seamless, intelligent conversations through cutting-edge AI, transforming customer and employee interactions.',
    },
    {
        iconClass: 'service-box_icon fa-solid fa-search add2',
        title: 'Search AI',
        description:
            'Unlocking deep insights with AI-driven search, making data retrieval smarter, faster, and more context-aware via RAG and Knowledge Graph approaches.',
    },
    {
        iconClass: 'service-box_icon fa-solid fa-earth-asia add2',
        title: 'Experience Accelerators',
        description:
            'Enhancing user and employee experiences by integrating AI with existing platforms for personalized, efficient, and engaging interactions.',
    },
    {
        iconClass: 'service-box_icon fa-solid fa-language add2',
        title: 'Linguistic Interfaces',
        description:
            'Breaking language barriers with advanced AI-powered translation, transcription, and voice synthesis in over 100 languages.',
    },
];

export const PRODUCTS = [
    {
        title: 'Translation',
        route: '/aiproducts/text-translate',
        description: 'Plain Text and Rich Text Translation, Document Translation, Audio/Video Translation',
        gif: 'assets/gif/product1.mp4'
    },
    {
        title: 'Transliteration',
        route: '/aiproducts/transliteration-universal-language-typing',
        description: 'Convert from any language to Enlish and vice-versa',
        gif: 'assets/gif/product2.mp4'
    },
    {
        title: 'Text to Speech',
        route: '/aiproducts/text-to-speech',
        description: 'TTS with over 2,500 voices and in any language! Emotional TTS, Text to Music, Voice Cloning',
        gif: 'assets/gif/product3.mp4'
    },
    {
        title: 'Speech to Text',
        route: '/aiproducts/speech-to-text-realtime',
        description: 'Transcribe voice in any language using your audio/video input devices or files',
        gif: 'assets/gif/product4.mp4'
    },

    {
        title: 'Speech to Speech',
        route: '/aiproducts/speech-to-speech',
        description: 'Translate voice in any language using your audio/video input devices or realtime',
        gif: 'assets/gif/product5.mp4'
    },
    {
        title: 'Document Translation',
        route: '/aiproducts/pdf-translator',
        description: 'Translate document in any language to another language',
        gif: 'assets/gif/product6.mp4'
    },
    {
        title: 'Audio/Video Translation',
        route: '/aiproducts/pdf-translator',
        description: 'Translate YouTube/local video/audio file in any language to another language',
        gif: 'assets/gif/product7.mp4'
    },
    {
        title: 'Optical Character Recognition',
        route: '/aiproducts/optical-character-recognition',
        description: 'Optical Character Recognition - Extract text from hand written/machine generated documents or images',
        gif: 'assets/gif/product8.mp4'
    },
    {
        title: 'Language Detection',
        route: '/aiproducts/language-detection',
        description: 'Detect language from text or from voice samples',
        gif: 'assets/gif/product9.mp4'
    },
    {
        title: 'Voice Cloning',
        route: '/aiproducts/language-detection',
        description: 'Clone your voice for TTS!',
        gif: 'assets/gif/product10.mp4'
    }
];

export const AI_SERVICES = [
    {
        title: 'Generative AI',
        route: 'https://www.genailia.com/ai-branches/post/generative-ai-66c8cd0f837a11d863de825e',
    },
    {
        title: 'NLP',
        route: 'https://www.genailia.com/ai-branches/post/natural-language-processing-66c9662157df42d8fd8e3a13',
    },
    {
        title: 'Machine Learning',
        route: 'https://www.genailia.com/ai-branches/post/machine-learning-66c968656422d3b2044bee67',
    },
    {
        title: 'Computer Vision',
        route: 'https://www.genailia.com/ai-branches/post/computer-vision-66c968beb96c94f50ed3f8c1',
    },
    {
        title: 'Deep Learning',
        route: 'https://www.genailia.com/ai-branches/post/deep-learning-66c9689493d1c484f7af7a0a',
    },
    {
        title: 'Prescriptive',
        route: 'https://www.genailia.com/ai-branches/post/prescriptive-analytics-66c9693caef3e518b24ebbdf',
    },
];

export const FAQS_NEW = [
    {
        name: 'General',
        items: [
            {
                question: 'Tell us a little about GenZ Technologies.',
                answer: 'GenZ Technologies (GenZers.AI | GenZers.Tech | Genailia.com) is a Startup India recognized company, founded in October 2021. Based in THub, Hyderabad, India, one of the largest startup incubators in the world, we specialize in delivering cutting-edge AI solutions.',
            },
            {
                question: 'What is Genailia?',
                answer: 'Genailia stands for Generative AI, Language Interfaces, and Accelerators. It is a hyper-generative AI platform designed to enhance the capabilities of large language models by providing true linguistic understanding.',
            },
            {
                question: 'Where do you operate from?',
                answer: 'We operate out of THub 2.0, Hyderabad, India.',
            },
            {
                question: 'What services do you provide?',
                answer: 'We provide affordable AI products and services, including SaaS, PaaS, on-premises, and private cloud solutions. Our offerings range from linguistic tools to advanced generative AI technologies.',
            },
            {
                question: 'Do you offer REST APIs?',
                answer: 'Yes, all our products are REST API enabled, secured with JWT, and come with comprehensive documentation for easy integration.',
            },
            {
                question: 'How can I provide suggestions or feedback?',
                answer: 'We welcome your feedback and suggestions. Please reach out to us through the "Contact Us" section on our website.',
            },
        ],
    },
    {
        name: 'Pricing',
        items: [
            {
                question: 'What pricing models do you support?',
                answer: 'To learn about our pricing models, please refer to the "Pricing" menu at the top of this web page.',
            },
            {
                question: 'Do you provide 24x7 support for the "Free" pricing model?',
                answer: 'The "Free" model offers basic support without specific time frames. For more assistance, please reach out via the "Contact" section.',
            },
        ],
    },
    {
        name: 'Languages',
        items: [
            {
                question: 'What languages does Genailia support?',
                answer: 'Genailia supports over 150 languages, covering a wide range of widely spoken languages globally.',
            },
            {
                question: 'How accurate are the translations?',
                answer: 'While machine translations are never 100% accurate, the accuracy depends on the domain and the language. For more specific information, please contact us.',
            },
        ],
    },
    {
        name: 'AI/ML',
        items: [
            {
                question: 'Which AI model do you use for translation?',
                answer: 'We utilize state-of-the-art deep learning models to generate our translations, ensuring high-quality results.',
            },
            {
                question: 'How many AI/ML models do you use?',
                answer: 'We utilize over 25 AI/ML models on state-of-the-art GPU machines to support Genailia. The specific models used are confidential.',
            },
            {
                question: 'Tell me about your Text to Speech (TTS) services.',
                answer: 'Our Text to Speech (TTS) services convert written text into natural and expressive speech. This is ideal for creating audio content, enhancing accessibility, and improving user engagement. We offer a variety of voices across multiple languages.',
            },
            {
                question: 'What is Emotional TTS?',
                answer: 'Emotional TTS is an advanced feature of our TTS service that adds emotional nuances to the synthesized speech. This makes the speech sound more natural and engaging by incorporating emotions such as happiness, sadness, and excitement.',
            },
            {
                question: 'How many voices do you offer for TTS?',
                answer: 'We offer over 2500 voices across various languages, providing a wide range of options for different needs and preferences.',
            },
            {
                question: 'What is Text to Music?',
                answer: 'Text to Music is a unique service that transforms written text into musical compositions. This innovative feature can be used for creating jingles, background music for videos, and more.',
            },
            {
                question: 'Do you provide Social Media Analytics?',
                answer: 'Yes, we offer comprehensive social media analytics services that provide insights into your social media performance, helping you make informed decisions and optimize your strategies.',
            },
            {
                question: 'What is eLLMo?',
                answer: 'eLLMo is our suite of products that includes Chatbots, GPT, Private GPT, LLM, and other generative AI solutions. These tools are designed to enhance productivity, streamline operations, and provide advanced AI capabilities.',
            },
            {
                question: 'Can eLLMo integrate with other platforms?',
                answer: 'Yes, eLLMo can integrate with various platforms such as Confluence, SharePoint, Jira, websites, text-based files, YouTube links, and more, providing a seamless experience across different tools and services.',
            },
        ],
    },
    {
        name: 'Enterprise',
        items: [
            {
                question: 'I am an enterprise customer, can I use my own SSO?',
                answer: 'Yes, we support Single Sign-On (SSO) integrations with SAML, OpenID Connect (OIDC), and other standard protocols to ensure secure and seamless authentication for your enterprise.',
            },
            {
                question: 'How can we get chatbot support for our enterprise?',
                answer: 'Our enterprise solutions include advanced chatbot support, which can be customized and integrated into your existing systems to provide automated customer service, internal support, and more.',
            },
            {
                question: 'Can we raise support tickets for issues?',
                answer: 'Yes, enterprise customers can raise support tickets through our dedicated support portal. Our team will assist you with any issues or queries you may have.',
            },
            {
                question: 'What kind of enterprise integrations do you support?',
                answer: 'We support a wide range of enterprise integrations, including Confluence, SharePoint, Jira, various websites, text-based files, YouTube links, and more. Our flexible API and integration capabilities ensure seamless connectivity with your existing systems.',
            },
        ],
    },
    {
        name: 'Support',
        items: [
            {
                question: 'How can I get support?',
                answer: 'For support, please reach out to us through the "Contact Us" section on our website. Our team is here to assist you with any questions or issues you may have.',
            },
            {
                question: 'Do you provide 24x7 support?',
                answer: 'Our support services vary based on the pricing model. For detailed information on support availability, please refer to the "Support" section on our website.',
            },
        ],
    },
];

export const FAQS = [
    // General
    [
        {
            question: 'Tell us a little about GenZ Technologies.',
            answer: 'GenZ Technologies (GenZers.AI | GenZers.Tech | Genailia.com) is a Startup India recognized company, founded in October 2021. Based in THub, Hyderabad, India, one of the largest startup incubators in the world, we specialize in delivering cutting-edge AI solutions.',
        },
        {
            question: 'What is Genailia?',
            answer: 'Genailia stands for Generative AI, Language Interfaces, and Accelerators. It is a hyper-generative AI platform designed to enhance the capabilities of large language models by providing true linguistic understanding.',
        },
        {
            question: 'Where do you operate from?',
            answer: 'We operate out of THub 2.0, Hyderabad, India.',
        },
        {
            question: 'What services do you provide?',
            answer: 'We provide affordable AI products and services, including SaaS, PaaS, on-premises, and private cloud solutions. Our offerings range from linguistic tools to advanced generative AI technologies.',
        },
        {
            question: 'Do you offer REST APIs?',
            answer: 'Yes, all our products are REST API enabled, secured with JWT, and come with comprehensive documentation for easy integration.',
        },
        {
            question: 'How can I provide suggestions or feedback?',
            answer: 'We welcome your feedback and suggestions. Please reach out to us through the "Contact Us" section on our website.',
        },
    ],
    // Pricing
    [
        {
            question: 'What pricing models do you support?',
            answer: 'To learn about our pricing models, please refer to the "Pricing" menu at the top of this web page.',
        },
        {
            question: 'Do you provide 24x7 support for the "Free" pricing model?',
            answer: 'The "Free" model offers basic support without specific time frames. For more assistance, please reach out via the "Contact" section.',
        },
    ],
    // Languages
    [
        {
            question: 'What languages does Genailia support?',
            answer: 'Genailia supports over 150 languages, covering a wide range of widely spoken languages globally.',
        },
        {
            question: 'How accurate are the translations?',
            answer: 'While machine translations are never 100% accurate, the accuracy depends on the domain and the language. For more specific information, please contact us.',
        },
    ],
    // Products and AI/ML
    [
        {
            question: 'Which AI model do you use for translation?',
            answer: 'We utilize state-of-the-art deep learning models to generate our translations, ensuring high-quality results.',
        },
        {
            question: 'How many AI/ML models do you use?',
            answer: 'We utilize over 25 AI/ML models on state-of-the-art GPU machines to support Genailia. The specific models used are confidential.',
        },
        {
            question: 'Tell me about your Text to Speech (TTS) services.',
            answer: 'Our Text to Speech (TTS) services convert written text into natural and expressive speech. This is ideal for creating audio content, enhancing accessibility, and improving user engagement. We offer a variety of voices across multiple languages.',
        },
        {
            question: 'What is Emotional TTS?',
            answer: 'Emotional TTS is an advanced feature of our TTS service that adds emotional nuances to the synthesized speech. This makes the speech sound more natural and engaging by incorporating emotions such as happiness, sadness, and excitement.',
        },
        {
            question: 'How many voices do you offer for TTS?',
            answer: 'We offer over 2500 voices across various languages, providing a wide range of options for different needs and preferences.',
        },
        {
            question: 'What is Text to Music?',
            answer: 'Text to Music is a unique service that transforms written text into musical compositions. This innovative feature can be used for creating jingles, background music for videos, and more.',
        },
        {
            question: 'Do you provide Social Media Analytics?',
            answer: 'Yes, we offer comprehensive social media analytics services that provide insights into your social media performance, helping you make informed decisions and optimize your strategies.',
        },
        {
            question: 'What is eLLMo?',
            answer: 'eLLMo is our suite of products that includes Chatbots, GPT, Private GPT, LLM, and other generative AI solutions. These tools are designed to enhance productivity, streamline operations, and provide advanced AI capabilities.',
        },
        {
            question: 'Can eLLMo integrate with other platforms?',
            answer: 'Yes, eLLMo can integrate with various platforms such as Confluence, SharePoint, Jira, websites, text-based files, YouTube links, and more, providing a seamless experience across different tools and services.',
        },
    ],
    // Enterprise
    [
        {
            question: 'I am an enterprise customer, can I use my own SSO?',
            answer: 'Yes, we support Single Sign-On (SSO) integrations with SAML, OpenID Connect (OIDC), and other standard protocols to ensure secure and seamless authentication for your enterprise.',
        },
        {
            question: 'How can we get chatbot support for our enterprise?',
            answer: 'Our enterprise solutions include advanced chatbot support, which can be customized and integrated into your existing systems to provide automated customer service, internal support, and more.',
        },
        {
            question: 'Can we raise support tickets for issues?',
            answer: 'Yes, enterprise customers can raise support tickets through our dedicated support portal. Our team will assist you with any issues or queries you may have.',
        },
        {
            question: 'What kind of enterprise integrations do you support?',
            answer: 'We support a wide range of enterprise integrations, including Confluence, SharePoint, Jira, various websites, text-based files, YouTube links, and more. Our flexible API and integration capabilities ensure seamless connectivity with your existing systems.',
        },
    ],
    // Support
    [
        {
            question: 'How can I get support?',
            answer: 'For support, please reach out to us through the "Contact Us" section on our website. Our team is here to assist you with any questions or issues you may have.',
        },
        {
            question: 'Do you provide 24x7 support?',
            answer: 'Our support services vary based on the pricing model. For detailed information on support availability, please refer to the "Support" section on our website.',
        },
    ],
];
export const eLLMoFAQS = [
    // General
    [
        {
            question: 'What is eLLMo?',
            answer: 'eLLMo, or Enterprise Large Language Model Outcomes, is a private large language model solution designed for enterprises. It combines advanced AI search capabilities with large language models to deliver generative insights and answer-based interaction, transforming how teams access information and utilize AI in the workplace.',
        },
        {
            question: 'What sets eLLMo apart in enterprise AI?',
            answer: 'eLLMo is a private GPT/LLM that offers tailored language capabilities, including Retrieval Augmented Generation (RAG), secure role-based access, and customizable AI for language-focused applications. It can be fine-tuned to specific organizational needs, making it ideal for versatile enterprise applications.',
        },
        {
            question: 'Can eLLMo handle diverse data formats?',
            answer: 'Yes, eLLMo supports various file formats, including TXT, DOC, DOCX, PPT, PPTX, PDF, HTML, MD, JSON, and extracts transcripts from speech, video, and audio files. This adaptability enhances its ability to work with a wide range of enterprise data sources.',
        },
        {
            question: 'How does eLLMo ensure data security?',
            answer: 'eLLMo offers secure role-based access, allowing organizations to control access to generative AI tools and private GPT features, ensuring data privacy and compliance with enterprise security standards.',
        },
        {
            question: 'What deployment options are available for eLLMo?',
            answer: 'eLLMo can be deployed as SaaS, PaaS, on-premises, or on a private cloud. It also supports deployment on public cloud platforms like AWS, Azure, GCP, and other providers, offering flexibility to meet different infrastructure needs.',
        },
    ],
    // Features and Capabilities
    [
        {
            question: 'What is Retrieval Augmented Generation (RAG) in eLLMo?',
            answer: 'RAG is an advanced LLM-based feature that combines search capabilities with generative AI. It enables eLLMo to retrieve and generate information efficiently, providing accurate and contextual answers to queries.',
        },
        {
            question: 'Does eLLMo support customization and model fine-tuning?',
            answer: 'Yes, eLLMo can be customized and fine-tuned to align with specific organizational knowledge models. This flexibility makes it adaptable for various applications within enterprise environments, including industry-specific language models.',
        },
        {
            question: 'What AI models does eLLMo use?',
            answer: 'eLLMo utilizes large language models optimized for enterprise use, including both small and large LLMs. These models are designed for language applications like Q&A, entity recognition, and private GPT integration, providing a comprehensive AI experience.',
        },
        {
            question: 'Can eLLMo integrate with existing enterprise platforms?',
            answer: 'Yes, eLLMo can integrate with popular platforms such as Confluence, SharePoint, and Jira, as well as other enterprise systems, offering a seamless experience across various tools and workflows.',
        },
    ],
    // Pricing and Support
    [
        {
            question: 'What pricing models does eLLMo offer?',
            answer: 'eLLMo provides flexible pricing options, including models suited for small language models, PaaS AI, and large language models. For more information, please refer to our "Pricing" section or contact our sales team.',
        },
        {
            question: 'Does eLLMo offer 24x7 support?',
            answer: 'eLLMo support options vary based on the chosen pricing model. For details about support availability and response times, please refer to the "Support" section on our website.',
        },
    ],
];


export const SOCIAL_NETWORKS = [
    {
        class: 'fa-brands fa-linkedin',
        link: 'https://www.linkedin.com/company/genailia/',
    }
    ,
    {
        class: 'fa-brands fa-youtube',
        link: 'https://www.youtube.com/@genailia',
    },
    {
        class: 'fa-brands fa-x-twitter',
        link: 'https://twitter.com/genailia',
    },
    {
        class: 'fa-brands fa-facebook',
        link: 'https://www.facebook.com/genzersdotai',
    },
    {
        class: 'fa-brands fa-instagram',
        link: 'https://www.instagram.com/genzers.ai/',
    },
    {
        class: 'fa-brands fa-discord',
        link: 'https://discord.gg/XhuYpufMPg',
    }
];

export const TAILORED_SOL = [
    {
        title: 'Banking & Finance',
        icon: 'fa-university',
        desc: 'AI/ML in your transformational journey',
        link: '../solutions/bfsi',
    },
    {
        title: 'Edtech',
        icon: 'fa-graduation-cap',
        desc: 'Education Evolves with Generative AI Integration',
        link: '../solutions/edtech',
    },
    {
        title: 'Tourism and Hospitality',
        icon: 'fa-plane',
        desc: 'Hospitality Redefined by Data Insights',
        link: '../solutions/tourism',
    },
    {
        title: 'Enterprise',
        icon: 'fa-building',
        desc: 'Private LLMs with Private Data On-Prem/Private Cloud',
        link: '../solutions/enterprise',
    },
    {
        title: 'Health Care',
        icon: 'fa-heartbeat',
        desc: 'Precision Care Through Advanced Analytics',
        link: '../solutions/healthcare',
    },
    {
        title: 'E-commerce',
        icon: 'fa-shopping-cart',
        desc: 'Personalized Shopping Experiences Thrive',
        link: '../solutions/ecommerce',
    },
    {
        title: 'Supply Chain',
        icon: 'fa-truck',
        desc: 'Efficiency Soars with Data Insights',
        link: '../solutions/supplychain',
    },
    {
        title: 'Manufacturing',
        icon: 'fa-industry',
        desc: 'Smart Factories Optimize Production Processes',
        link: '../solutions/manufacturing',
    },
    {
        title: 'Insurance',
        icon: 'fa-shield-alt',
        desc: 'Automating Claims and Risk Management with AI',
        link: '../solutions/insurance',
    },
    {
        title: 'Agriculture',
        icon: 'fa-seedling',
        desc: 'AI Solutions for Modern Farming and Sustainability',
        link: '../solutions/agriculture',
    },
    {
        title: 'Telecommunications',
        icon: 'fa-phone-alt',
        desc: 'Enhanced Customer Service and Network Optimization with AI',
        link: '../solutions/telecommunications',
    },
    {
        title: 'Recruitment',
        icon: 'fa-user-tie',
        desc: 'AI-Powered Recruitment and Candidate Screening',
        link: '../solutions/recruitment',
    },
    {
        title: 'Legal',
        icon: 'fa-gavel',
        desc: 'AI-Driven Document Review and Legal Research',
        link: '../solutions/legal',
    },
    {
        title: 'Government',
        icon: 'fa-landmark',
        desc: 'AI Solutions for Streamlining Public Services',
        link: '../solutions/government',
    },
    {
        title: 'Automobile',
        icon: 'fa-car',
        desc: 'Driving Innovation with AI-Powered Solutions in the Automotive Industry',
        link: '../solutions/automobile',
    },
    {
        title: 'Advertising',
        icon: 'fa-bullhorn',
        desc: 'AI-Driven Ad Campaigns and Personalization',
        link: '../solutions/advertising',
    }
];