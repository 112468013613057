<div class="container py-4 privacy">
    <div>
        <h1 class="privacy-heading-text">Privacy Policy</h1>
    </div>
    <h2 class="privacy-subheading-text">v1.1 - Last Edited 19th August
        2024</h2>

    <div class="privacy-content">
        <h2 class="privacy-subheading-text">1. Introduction</h2>
        <p>We are Genailia (Genz Technologies Pvt Ltd) ("Company", "we", "us",
            "our").</p>
        <p>GenZ Technologies Pvt Ltd ("GenZ Technologies," "Company," "We," "Us," or "Our") is a provider of AI-powered solutions and SaaS products. Our registered office is located at Floor 3, T-Hub, Plot No 1/C, Sy No 83/1, Hyderabad Knowledge City, Serilingampally, Hyderabad, Telangana - 500081, India. This Privacy Policy outlines how We collect, use, and protect Your information when You access or use Our platform ("Platform") through any medium, including Our website, mobile applications, or other related channels.</p>
        <p>By accessing and using Our Platform, You agree to the collection and use of Your information in accordance with this Privacy Policy. It is important that You read this policy carefully to understand how We handle Your data.</p>

        <h2 class="privacy-subheading-text">2. Choices Available to You Regarding Collection, Use, and Distribution of Your Information</h2>
        <p>Your participation in sharing Your Information is voluntary. If You choose not to share certain information with Us, You may not have access to all features and services offered on Our Platform. If You register with Us, You may request to remove or block Your Information from Our database. However, some of Your Information may remain archived on Our servers even after Your Account is deleted or terminated.</p>
        <p>If We intend to use Your Information for any commercial purposes, We will notify You at the time of collection and provide You with the option to opt-out.</p>

        <h2 class="privacy-subheading-text">3. Information We Collect</h2>
        <p>We may collect various types of information from You, including:</p>
        <ul>
            <li>Anonymous information through server logs, including computer-identification information obtained from "cookies" sent to Your browser, IP address, geographical location, the domain server through which You access Our service, and the type of computer and web browser You are using.</li>
            <li>Information You provide to Us, such as Your name, email address, profile pictures, gender, date of birth, residential/office address, and any other details You submit during the creation of Your Account or while using Our services.</li>
            <li>Information about the timing, frequency, and pattern of Your visits to Our Platform.</li>
            <li>Communications sent to Us or through Our Platform, including content and metadata.</li>
            <li>Personal Information and Sensitive Personal Data or Information (SPDI), such as credentials for use of the Platform, financial information, and any biometric data You may provide.</li>
        </ul>

        <h2 class="privacy-subheading-text">4. Additional Information We Collect</h2>
        <ul>
            <li>Details about the pages You visit or access.</li>
            <li>Links clicked on Our Platform.</li>
            <li>The number of times You access Our Platform.</li>
            <li>Transaction information related to Your use of the Platform.</li>
            <li>The date and time You access Our Platform.</li>
            <li>The Platform address, if any, that linked You to Our Platform.</li>
        </ul>

        <h2 class="privacy-subheading-text">5. Use of Information</h2>
        <p>We use Your Information for the following purposes:</p>
        <ul>
            <li>To ensure Our services are presented in the most efficient manner for Your use.</li>
            <li>To provide, operate, and maintain Our services.</li>
            <li>To personalize Our Platform for You.</li>
            <li>To develop new products, services, features, and functionality.</li>
            <li>To process Your transactions.</li>
            <li>To send non-marketing commercial communications.</li>
            <li>To send You email notifications and newsletters, if requested.</li>
            <li>To send marketing communications that may be of interest to You (with the option to opt-out).</li>
            <li>To deal with inquiries and complaints made by or about You relating to Our Platform.</li>
            <li>To communicate and transmit statistical information to third-party analytics platforms for the purpose of customizing Your experience.</li>
            <li>To verify compliance with the terms and conditions governing the use of Our Platform.</li>
        </ul>
        <p>You hereby expressly grant Your consent for Us to use Your entity name and trademark on the Platform for the purpose of marketing and promoting Our services.</p>
        <p>If You post information on Our Platform for others to view, You do so at Your own risk. We cannot control the actions of other users with whom You share information.</p>

        <h2 class="privacy-subheading-text">6. Disclosure of Collected Information</h2>
        <p>We do not sell Your information. However, We may disclose Your information in the following circumstances:</p>
        <ul>
            <li><strong>Business Transfers:</strong> Your information may be shared during the diligence process and transferred to a potential acquirer, successor, or assignee as part of a proposed merger, acquisition, or sale of assets.</li>
            <li><strong>Service Requirements:</strong> Employees, business associates, and third parties may have access to Your information as needed to fulfill Our services. We may use Your information internally for training, data analytics, research, and other business-related purposes.</li>
            <li><strong>Legal Requirements:</strong> Your information may be disclosed if required by law, regulation, legal process, or governmental request, or to enforce Our policies, detect fraud, or protect Our rights, property, or the safety of Our users.</li>
        </ul>

        <h2 class="privacy-subheading-text">7. Use of Anonymous Traffic Information</h2>
        <ul>
            <li>To deliver personalized service.</li>
            <li>To recognize Your access privileges to Our Platform.</li>
            <li>To track Your entries in promotions.</li>
            <li>To help diagnose problems with Our server.</li>
            <li>To administer Our Platform.</li>
            <li>To track Your session for a better understanding of how users interact with Our Platform.</li>
        </ul>

        <h2 class="privacy-subheading-text">8. Where We Store and How We Protect Your Information</h2>
        <p>We store Your information in the United States, India, and Singapore. We take reasonable steps to protect Your information from unauthorized access, alteration, disclosure, or destruction, using physical, electronic, and managerial safeguards. However, no method of data transmission or storage is completely secure. You are responsible for keeping Your account login information, including Your password, secure.</p>
        <p>If You suspect unauthorized use of Your account or discover any security breaches, please notify Us immediately.</p>

        <h2 class="privacy-subheading-text">9. Security</h2>
        <p>We implement various security measures to safeguard Your information, including limiting access to Your SPDI to trusted third parties, training employees on data protection, and conducting internal audits of Our privacy practices. Despite Our best efforts, We cannot guarantee perfect security, and We are not liable for unauthorized access to Your information.</p>
        <p>Your information will be retained as long as necessary for legitimate business purposes, after which it will be deleted or anonymized, unless deletion is not possible (e.g., due to backup archives).</p>

        <h2 class="privacy-subheading-text">10. Exceptions</h2>
        <p>We reserve the right to terminate accounts of minors if We believe Our Platform is not safe for them. Unsolicited information sent to Us is not covered by this policy, and We may use or disclose it without restriction.</p>

        <h2 class="privacy-subheading-text">11. Links to Third-Party Sites</h2>
        <p>Our services may link to third-party websites or applications ("Third-Party Applications"). We are not responsible for the privacy practices of these Third-Party Applications, and We encourage You to review their privacy policies before engaging with them.</p>

        <h2 class="privacy-subheading-text">12. Correcting Inaccuracies in Information</h2>
        <p>You have the right to request access to, review, modify, and update the information We hold about You. You may also request the removal of any inaccurate, irrelevant, or unlawfully processed information.</p>

        <h2 class="privacy-subheading-text">13. Cookies</h2>
        <p>We use cookies and other tracking technologies to improve Our Platform and services. Cookies store user preferences and help Us understand how users interact with Our Platform. Most browsers allow You to control cookies, including whether or not to accept them and how to remove them.</p>

        <h2 class="privacy-subheading-text">14. Your Rights</h2>
        <ul>
            <li><strong>The Right to Rectification:</strong> You have the right to rectify inaccurate or incomplete information.</li>
            <li><strong>The Right of Deletion:</strong> You may request deletion of Your information under certain conditions.</li>
            <li><strong>The Right to Withdraw:</strong> You may withdraw consent to the processing of Your data, which may result in restricted access to Our Platform and services.</li>
            <li><strong>The Right to Notification:</strong> You have the right to be notified of any rectification or erasure of Your information or restriction of processing.</li>
            <li><strong>The Right to Access:</strong> You may request copies of Your information.</li>
            <li><strong>The Right to Opt-In/Out:</strong> You may give or withdraw consent for processing Your information.</li>
        </ul>
        <p>If You wish to exercise any of these rights, please contact Us at <a href="mailto:connect@genzers.tech">connect&#64;genzers.tech</a>. We will respond within seven (7) business days.</p>

        <h2 class="privacy-subheading-text">15. User Declarations</h2>
        <ul>
            <li>You understand that Your information is being collected.</li>
            <li>You understand the purpose for which Your information is being collected.</li>
            <li>You understand the intended recipients of the information.</li>
            <li>You understand that You have the option not to provide the requested information.</li>
            <li>You understand that You may withdraw consent for processing Your information, but this may limit Your access to Our services.</li>
            <li>You have read and understood this Privacy Policy and other policies governing the use of Our Platform.</li>
            <li>By agreeing to this Policy, You consent to Our processing of Your information as described herein.</li>
        </ul>

        <h2 class="privacy-subheading-text">16. Disclaimer</h2>
        <p>We are not liable for any loss or damage resulting from the disclosure of information due to unauthorized access, errors, or other uncontrollable factors.</p>

        <h2 class="privacy-subheading-text">17. Grievance Redressal</h2>
        <p>In accordance with applicable laws, the name and contact details of the Grievance Officer are provided below:</p>
        <p>Name: Karthik Upadrasta</p>
        <p>Email: <a href="mailto:connect@genzers.tech">connect&#64;genzers.tech</a></p>
        <p>If You have any grievances, please send a written request or an email signed with an electronic signature, including Your contact information and the nature of Your grievance.</p>

        <h2 class="privacy-subheading-text">18. Contact Us</h2>
        <p>If You have any questions about this Privacy Policy, You can contact Us:</p>
        <ul>
            <li>By visiting this page on Our website: https://www.genailia.com/privacy-policy</li>
            <li>By sending Us an email: <a href="mailto:connect@genzers.tech">connect&#64;genzers.tech</a></li>
        </ul>
        <p>BY AGREEING TO THE TERMS OF THIS POLICY, YOU AGREE TO OUR PROCESSING OF YOUR PERSONAL INFORMATION FOR THE PURPOSES GIVEN HEREIN.</p>
    </div>
</div>
