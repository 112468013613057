import { Injectable } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { HttpClient } from '@angular/common/http';
import { Observable,of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProvideLanguageService {

  private baseUrl: string = 'https://api.genailia.com/api/v2';
  languages: any[] = [];

  constructor(
    private authService: AuthService,
    private http: HttpClient,
  ) {}

  fetchLanguages(): Observable<string[]> {
      if(this.languages.length > 0 ) {
          return of(this.languages);
      }
    return this.authService.fetchToken().pipe(
      switchMap(token => {
        const headers = {
          "Authorization": "Bearer " + token
        };
        return this.http.get<{ fullLocale: string }[]>(`${this.baseUrl}/localeList`, { headers: headers });
      }),
      map(response => {
        this.languages = response.map(item => item.fullLocale);
        this.languages.sort();
        return this.languages;
      })
    );
  }
  getLanguages(): string[] {
    return this.languages;
  }
}
