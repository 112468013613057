<div class="container py-4 partners">
  <div class="partners-heading my-4">
    <div class="partners-heading-hyphon"></div>
    <div class="partners-heading-text">Partnerships</div>
  </div>

  <div class="partners-content">
    <p>
      At Genailia, we believe in the power of collaboration. By partnering with innovative companies, organizations, and institutions, we are able to push the boundaries of AI and deliver groundbreaking solutions that benefit businesses and communities alike.
    </p>
    
    <p>
      <br/>Our partnerships are built on mutual trust, shared vision, and a commitment to driving technological advancements. Whether you're a technology provider, a service integrator, or an academic institution, we are eager to explore how we can work together to create value and impact.
    </p>
    
    <p>
      <br/>If you're interested in exploring partnership opportunities with us, we invite you to connect with our team. Together, we can unlock new possibilities and achieve greater success.
    </p>
    
    <p>
      <br/><a class="btn btn-primary btn-outline-info rounded-pill" href="/contact">Contact Us</a> to get started on a journey of collaboration and innovation.
    </p>
  </div>
</div>
