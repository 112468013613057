import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['../../../../app.common.scss', './about.component.scss']
})
export class AboutComponent {

    ngOnInit(): void {
        this.myservice.setTitleAndMeta("About Us", "About Us - Discover our mission & AI-driven language solutions. Learn about our commitment to innovation, and cutting-edge technology.", "about us, all about genailia, team, careers, partnerships, our thesis, vision, mission, people, company, investors, associations", "about");
    }

    constructor(
        private myservice: MyService,
    ) {
    }

}
