import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AIProductsService } from 'src/app/aiproducts/services/aiproducts.service';
import { Location } from "@angular/common";
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

import { MENU, PRODUCTS, RESOURCES, COMPANY } from '../../constants/navbar';
import { SOCIAL_NETWORKS } from '../../constants/home';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['../../../app.common.scss', './footer.component.scss'],
})
export class FooterComponent implements OnInit {
    date = new Date();
    year = this.date.getFullYear();

    products = PRODUCTS;
    company = COMPANY;
    socialNetworks = SOCIAL_NETWORKS;
    menuItems = MENU;
    resources = RESOURCES;
    ctaLabel = "Try Now";
    ctaLink = "/aiproducts";

    subscribeForm: FormGroup;
    subscriptionSuccess: boolean = false;
    changeLinks = ['/ellmo', '/blah', '/solutions/']; // Updated to only check the root

    constructor(
        private fb: FormBuilder, private router: Router,
        private translatorService: AIProductsService,
        private location: Location,
    ) {
        this.subscribeForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    ngOnInit(): void {
        const path = this.location.path();
        if (this.isPathInChangeLinks(path)) {
            this.ctaLabel = "Book a Demo";
            this.ctaLink = "/get-demo";
        }

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            const path = this.location.path();
            console.log("changelinks path is " + path);
            if (this.isPathInChangeLinks(path)) {
                this.ctaLabel = "Book a Demo";
                this.ctaLink = "/get-demo";
            } else {
                this.ctaLabel = "Try Now";
                this.ctaLink = "/aiproducts";
            }
        });
    }

    // Function to check if the current path should trigger a change
    isPathInChangeLinks(path: string): boolean {
        // Match if the path exactly matches or starts with /solutions/
        return this.changeLinks.some(link => path === link || path.startsWith(link));
    }

    onSubmit() {
        if (this.subscribeForm.valid) {
            const email = this.subscribeForm.value.email;

            this.translatorService.subscribenewsletter(email).subscribe(
                res => {
                    console.log('Subscription successful:', res);
                    this.subscriptionSuccess = true;  // Set success flag to true
                    this.subscribeForm.reset();  // Optionally reset the form
                },
                err => {
                    console.error('Subscription failed:', err);
                    this.subscriptionSuccess = false;  // Handle error case
                }
            );
        }
    }
}
