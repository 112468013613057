import { Component, OnInit } from '@angular/core';
import { CookieServicey } from 'src/app/services/cookie.service';

interface Toggle {
  label: string;
  isChecked: boolean;
}
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  ifCookie:boolean = false;
  customize:boolean = true
  toggles: Toggle[] = [
    { label: 'Strictly Necessary', isChecked: true },
    { label: 'Performance Cookies', isChecked: false },
    { label: 'Functional Cookies', isChecked: false },
    { label: 'Targeting Cookies', isChecked: false },
  ];

  constructor(private cookiey:CookieServicey) {}

  ngOnInit() {
    this.ifCookie = true
    const userPreferences = this.cookiey.getUserPreferences();
    if (userPreferences && userPreferences.necessary) {
      // Load necessary and analytics cookies based on user preferences
      console.log(userPreferences)
      this.cookiey.acceptCookies();
      this.ifCookie = false
    }
  }


  toggleClicked(toggle: Toggle) {
    toggle.isChecked = !toggle.isChecked;
    console.log(this.toggles)
  }

  // isChecked = false;

  // toggle() {
  //   this.isChecked = !this.isChecked;
  // }
  acceptCookies() {
    this.cookiey.acceptCookies();
    this.ifCookie = false;
    this.customize = true
  }

  rejectCookies() {
    this.cookiey.saveUserPreferences({ necessary: true, performance: this.toggles[1].isChecked, functional: this.toggles[2].isChecked, targeting: this.toggles[3].isChecked })

    this.ifCookie = false
  }
  customizey(){
    this.customize =!this.customize
  }

  save(){
    console.log({ necessary: this.toggles[0].isChecked, performance: this.toggles[1].isChecked, functional: this.toggles[2].isChecked, targeting: this.toggles[3].isChecked })
    this.ifCookie = false;
    this.customize = true
    this.cookiey.saveUserPreferences({ necessary: this.toggles[0].isChecked, performance: this.toggles[1].isChecked, functional: this.toggles[2].isChecked, targeting: this.toggles[3].isChecked })

  }
}
