import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class CookieServicey {

  private necessaryCookieName = 'necessaryCookie';
  private performanceCookieName = 'performanceCookie';
  private functionalCookieName = 'functionalCookie';
  private targetingCookieName = 'targetingCookie';
  private userPreferenceCookieName = 'cookiePreferences';

  constructor(private cookieService: CookieService) {}

  acceptCookies() {
    // this.cookieService.set(this.necessaryCookieName, 'necessary cookie');
    // this.cookieService.set(this.performanceCookieName, 'performance cookie');
    // this.cookieService.set(this.functionalCookieName, 'functional cookie');
    // this.cookieService.set(this.targetingCookieName, 'targeting cookie');
    this.saveUserPreferences({ necessary: true, performance: true, functional: true, targeting: true });
  }

  rejectCookies() {
    this.cookieService.delete(this.necessaryCookieName);
    this.cookieService.delete(this.performanceCookieName);
    this.cookieService.delete(this.functionalCookieName);
    this.cookieService.delete(this.targetingCookieName);
    this.saveUserPreferences({ necessary: true, performance: false, functional: false, targeting: false });
  }

 saveUserPreferences(preferences: {
    necessary: boolean,
    performance: boolean,
    functional: boolean,
    targeting: boolean
  }) {
    console.log("function called")
    console.log(JSON.stringify(preferences))
    this.cookieService.set(this.userPreferenceCookieName, decodeURIComponent(JSON.stringify(preferences)),30);
  }

  getUserPreferences() {
    const preferencesString = this.cookieService.get(this.userPreferenceCookieName);
    return preferencesString ? JSON.parse(preferencesString) : null;
  }
}
