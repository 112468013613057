import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-associations',
    templateUrl: './associations.component.html',
    styleUrls: ['../../../../app.common.scss', './associations.component.scss']
})
export class AssociationsComponent {

    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Associations","Genailia thrives in a vibrant ecosystem, leveraging key partnerships with top startup platforms to lead in AI and tech innovation.","about us, all about genailia, team, careers, partnerships, our thesis, vision, mission, people, company, investors, associations","associations");
    }

    constructor(
        private myservice: MyService,
    ) {
    }

}
