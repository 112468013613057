
export const PRODUCTS = [
    { title: 'Text Translation', icon: 'fa-solid fa-language', link: '/aiproducts/text-translate', additional: 'Translate text between languages' },
    { title: 'Transliteration', icon: 'fa-solid fa-keyboard', link: '/aiproducts/transliteration-universal-language-typing', additional: 'Type in multiple languages easily' },
    { title: 'Text to Speech - TTS', icon: 'fa-solid fa-volume-up', link: '/aiproducts/text-to-speech', additional: 'Convert text into natural speech' },
    { title: 'Optical Character Recognition (OCR)', icon: 'fa-solid fa-eye', link: '/aiproducts/optical-character-recognition', additional: 'Extract text from images and files' },
    { title: 'Language Detection', icon: 'fa-solid fa-search', link: '/aiproducts/language-detection', additional: 'Detect language of any text' },
    { title: 'Speech to Text', icon: 'fa-solid fa-microphone-alt', link: '/aiproducts/speech-to-text-realtime', additional: 'Convert spoken words into text' },
    { title: 'Speech to Speech', icon: 'fa-solid fa-exchange-alt', link: '/aiproducts/speech-to-speech', additional: 'Translate speech in real-time' },
    { title: 'Audio Video Translator', icon: 'fa-solid fa-exchange-alt', link: '/aiproducts/av-translator', additional: 'Translate Audio Video in real-time' },

    //{ title: 'Document Translator', icon: 'fa-solid fa-file-alt', link: '/aiproducts/pdf-translator', additional: 'Translate documents in various formats' },
    //{ title: 'Audio/Video Translator', icon: 'fa-solid fa-film', link: '/aiproducts/av-translator', additional: 'Translate audio and video content' },
    //{ title: 'Voice Cloning', icon: 'fa-solid fa-robot', link: '/aiproducts/voice-cloning', additional: 'Create synthetic voices from samples' },
    //{ title: 'Unstructured Data Extractor', icon: 'fa-solid fa-database', link: '/aiproducts/keyword-data-extraction', additional: 'Extract key data from raw content' },
    //{ title: 'Youtube Summary', icon: 'fa-solid fa-youtube', link: '/aiproducts/youtube-summary', additional: 'Generate summaries of YouTube videos' },
];


export const GENPRODUCTS = [
    { title: 'Conversational AI - eLLMo', icon: 'fa-solid fa-comments', link: '/ellmo', additional: 'Translate text between languages' },
    { title: 'Multilingual AI - Lingo', icon: 'fa-solid fa-globe', link: '/lingo', additional: 'Type in multiple languages easily' },
]

export const COMPANY = [
    { title: 'About', link: '/about' },
    { title: 'Leadership', link: '/leadership' },
    { title: 'Careers', link: '/careers' },
    { title: 'Partners', link: '/partners' },
    { title: 'Investors', link: '/investors' },
    { title: 'Thesis', link: '/thesis' },
    { title: 'Ask Genailia GPT', link: 'https://chatgpt.com/g/g-5NQWMkLA6-genailia-gpt' },
    { title: 'Associations', link: '/associations' },
];

export const RESOURCES = [
    { title: 'Announcements', link: '/announcement' },
    { title: 'AI Summaries', link: '/ai-branches' },
    { title: 'Media Library', link: '/media' },
    { title: 'Press Coverage', link: '/press' },
    { title: 'FAQ', link: '/faq' },
    { title: 'Survey', link: 'https://surveys.genailia.com/zs/Nz60jY' },
    { title: 'Solutions', link: '/solutions' },
    { title: 'Platform', link: '/platform' },
    { title: 'Glossary', link: '/glossary' },
    { title: 'Blog', link: '/blog' },
];

export const MEMBERS = [
    { title: 'Login', url: '/login' },
    { title: 'Sign Up', url: '/signup' },
];


export const MENU = [
    {
        title: 'Home',
        link: '/home',
    },{
        title: 'eLLMo',
        link: '/ellmo',
    },{
        title: 'Lingo',
        link: '/lingo',
    },
    {
        title: 'Pricing',
        link: '/pricing',
    },
    
        {
        title: 'Free Tools',
        link: '/aiproducts',
    },
    {
        title: 'Contact',
        link: '/contact',
    },
    {
        title: 'Login',
        link: 'https://app.genailia.com/',
    }
  ];


export const FEATURES = [
    {

        title: "RAG (Retrieval Augmented Generation)",
        description: "Explore Q&A with RAG's LLM-based Model. Leverage LLM-based PaaS AI, Large Language Model API, and Unlock Generative AI Search for Enhanced Efficiency"
    },
    {

        title: "Secure Role-Based Access",
        description: "Fortify Data Security with Role-Based Access to Empower Teams through Generative AI and Private GPT. Customize and Fine-Tune Models in Enterprise and Organizational Environments."
    },
    {

        title: "Flexible Pricing Models",
        description: "Versatile Pricing Models for Small LLM, Small Language Model, LLM-based PaaS AI, and the Best Large Language Model AI. Unlock AI for Language with GPT in Various Languages."
    },
    {

        title: "Flexible Deployment Options",
        description: "Opt for SaaS, PaaS, On-Premises, or Private Cloud, Empowered by LLM Vector Search, LLM-based Entity Recognition with Prompt, Large Language Model-based API, Private GPT."
    },
    {

        title: "Intuitive Interface",
        description: "Next-Level Interface, Enhanced with Search with AI, LLM-based Q&A Model, PaaS AI, Large Language Model API, Generative AI Search, and Advanced LLM Features. Explore Best Large Language Model AI."
    },
    {

        title: "Performance & Accuracy",
        description: "Large Language Model API, and Generative AI Search. Harness the Power of Generative AI Models, Small LLM, LLM Vector Search, Entity Recognition, and Sentiment Analysis with Prompts."
    },
    {

        title: "Cost-Effective",
        description: "Affordability with Advanced Capabilities – Search with AI, LLM-based Q&A Model, PaaS AI. Explore the Best in Large Language Model AI, Understand Large Models, Private GPT and Private LLM"
    },
    {

        title: "Prompt Templates",
        description: "Refine Interactions with Prompt Templates: Perform Sentiment Analysis with Generative AI and Unlock Enhanced Searching with AI with Best Large Language Mode"
    },
    {

        title: "Multi-Level Custom Prompts",
        description: "Explore LLM-based Entity Recognition with Prompt and Prompt Sentiment Analysis with Generative AI. Gain In-Depth Understanding with Large Language Models Overview, Including Private GPT and Private LLM."
    },

]


export const LINGO = [
    {
        "title": "100 Languages",
        "description": "Lingo supports over 100 languages, enabling seamless communication across global audiences through advanced AI-driven translation, transcription, and text-to-speech capabilities."
    },
    {
        "title": "Text to Speech",
        "description": "Convert written text into natural-sounding speech with over 2,500 voices, covering a wide range of languages and accents to enhance user experiences and accessibility."
    },
    {
        "title": "Transcription/Speech to Text",
        "description": "Accurately transcribe spoken language into text with timestamping, speaker identification, and the ability to handle multiple languages and dialects."
    },
    {
        "title": "Audio/Video Translation",
        "description": "Translate audio and video content in real time, making it accessible to multilingual audiences by converting spoken language into their desired language."
    },
    {
        "title": "Document Translation",
        "description": "Translate entire documents quickly and accurately, supporting a wide variety of file formats, including PDFs, Word documents, and rich text formats."
    },
    {
        "title": "Language Detection",
        "description": "Automatically detect the language of both text and spoken content, providing insights into multilingual data and streamlining translation workflows."
    },
    {
        "title": "Emotional TTS",
        "description": "Bring emotional nuance to speech with emotional Text-to-Speech (TTS), enhancing user interactions by delivering expressive and dynamic voice responses."
    },
    {
        "title": "Optical Character Recognition",
        "description": "Extract text from images and scanned documents through Optical Character Recognition (OCR), enabling translation, transcription, and text analysis of visual content."
    },
    {
        "title": "NLP Tasks",
        "description": "Perform various NLP tasks such as classification, summarization, sentiment analysis on the extracted language using the various features of Lingo."
    }

]

