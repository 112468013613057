import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, delay, of, switchMap, map } from 'rxjs';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { ProvideLanguageService } from 'src/app/provide-language.service';

export const VIDEOS = [
    { value: 1, name: 1 },
    { value: 2, name: 2 },
    { value: 3, name: 3 },
    { value: 4, name: 4 },
    { value: 5, name: 5 },
    { value: 6, name: 6 },
    { value: 7, name: 7 },
    { value: 8, name: 8 },
    { value: 9, name: 9 },
]

export const FORMATS = [
    { value: "PDF", name: "PDF" },
    { value: "HTML", name: "HTML" }
]

export interface SlugResponse {
    from: string;
    to: string;
    from1: string;
    to1: string;
};

@Injectable({
    providedIn: 'root'
})
export class AIProductsService {

    private baseUrl: string = 'https://api.genailia.com/api/v2';

    constructor(
        private http: HttpClient,
        private pls: ProvideLanguageService,
        private authService: AuthService
    ) { }

    subscribenewsletter(email: string): Observable<any> {

        const requestBody = {
            email
        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/subscribe-newsletter`, requestBody, { headers: headers });
            })
        );
    }

    translate(text: any, from: string, targetLanguage: string): Observable<any> {

        const requestBody = {
            text,
            from,
            targetLanguage
        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/translate`, requestBody, { headers: headers });
            })
        );
    }
    
    subscribing(text: any): Observable<any> {

        const requestBody = {
            text
        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/subscribe-newsletter`, requestBody, { headers: headers });
            })
        );
    }
    // demo 

    languageDetection(text: any): Observable<any> {
        const requestBody = {
            text
        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/detectLanguage`, requestBody, { headers: headers });
            })
        );
    }

    xlit(text: any, targetLanguage: string,): Observable<any> {

        const requestBody = {
            text,
            targetLanguage

        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/transliterate`, requestBody, { headers: headers });
            })
        );
    }

    engxlit(text: any, sourceLanguage: string, targetLanguage: string = "English"): Observable<any> {

        const requestBody = {
            text,
            sourceLanguage,
            targetLanguage
        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/inbound/engtransliterate`, requestBody, { headers: headers });
            })
        );
    }

    translateTextToSpeech(text: string, gender: string, voiceId: any): Observable<any> {
        const requestBody = {
            text,
            voiceId,
        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/tts`, requestBody, { headers: headers });
            })
        );

    }

    
    getTtsList(
        persona: string,
        style: string,
        language: string,
        gender: string,
        voiceType: string,
    ): Observable<any> {
        const endpoint = `${this.baseUrl}/ttsList`;
        const params = new URLSearchParams({
            persona,
            style,
            language,
            gender,
            voiceType
        });
        const url = `${endpoint}?${params.toString()}`;
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.get(url, { headers: headers });
            })
        );
    }
    translateSpeechToSpeech(data: any, from: string, to: string, speechOutput: boolean, cause: string, reduceNoise: string,
        ttsSpeed: string, callerAck: string, hexagoncall: boolean, transcriptionModel: string = '', ttsModel: string = '', translationModel: string = '',
        additionalInfo: string = '', automateResponses: string = '', textToSpeak: string = '',): Observable<any> {

        const requestBody = {
            data,
            from,
            to,
            speechOutput,
            cause,
            reduceNoise,
            ttsSpeed,
            callerAck,
            hexagoncall,
            additionalInfo,
            automateResponses,
            transcriptionModel,
            ttsModel,
            translationModel,
            textToSpeak,

        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/outbound/sts`, requestBody, { headers: headers });
            })
        );
    }

    stitchAVTranslate(
        sample: any,
        sampleStartSecond: any,
        sampleEndSecond: any,
        voiceSpeed: any,
        voiceId: any,
        audioOutputOnly: any,
        sourceLanguage: any,
        targetLanguage: any,
        englishSourceOrTarget: any,
        keepBackground: any,
        lipSync: any,
        transcriptInSourceLanguage: any,
        transcriptInTargetLanguage: any,
        transcriptInEnglishLanguage: any,
        data: any,
        operation: any,
        speechtospeech:any
    ): Observable<any> {
        const requestBody = {
            sample,
            sampleStartSecond,
            sampleEndSecond,
            voiceSpeed,
            voiceId,
            audioOutputOnly,
            sourceLanguage,
            targetLanguage,
            englishSourceOrTarget,
            keepBackground,
            lipSync,
            transcriptInSourceLanguage,
            transcriptInTargetLanguage,
            transcriptInEnglishLanguage,
            data, operation
        };


        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    Authorization: 'Bearer ' + token,
                };
                return this.http.post(
                    `${this.baseUrl}/stitchAVTranslate`,
                    requestBody,
                    { headers: headers }
                );
            })
        );
  
    }





    translateSpeechToTextRealtime(base64: any, from: string, to: string, cause: string, gpu: string, reduceNoise: string,
        ttsSpeed: string, hexagonCall: boolean, transcriptionModel: string = '', ttsModel: string = '', translationModel: string = ''): Observable<any> {

        const requestBody = {
            base64,
            from,
            to,
            cause,
            gpu,
            reduceNoise,
            ttsSpeed,
            hexagonCall,
            transcriptionModel,
            ttsModel,
            translationModel,


        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/sttrealtime`, requestBody, { headers: headers });
            })
        );


    }

    translateSpeechToTextFile(
        sample: any,
        includeTimestamp: any,
        performTranslate: any,
        operation: any,
        performTts: any,
        data: any,
        sourceLanguage: any,
        targetLanguage: any,
        languageDetectionModel: any,
        transcriptionModel: any
    ): Observable<any> {
        const requestBody = {
            sample,
            includeTimestamp,
            performTranslate,
            operation,
            performTts,
            data,
            sourceLanguage,
            targetLanguage,
            languageDetectionModel,
            transcriptionModel,

        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/transcribe`, requestBody, { headers: headers });
            })
        );



    }

    getEmergencySummary(callerLanguage: any, operatorLanguage: any, voicesBase64s: any, voiceTranscriptsInOperatorLanguage: any, operatorTimezome: any): Observable<any> {
        const requestBody = {
            callerLanguage,
            operatorLanguage,
            voicesBase64s,
            voiceTranscriptsInOperatorLanguage,
            operatorTimezome,
        };

        const headers = {
            "Authorization": "Bearer " + this.authService.getToken()
        };

        return this.http.post(`${this.baseUrl}/leads/callsummary`, requestBody, { headers: headers });

    }

    translateSpeechToTextMic(
        sample: any,
        operation: any,
        includeTimestamp: any,
        performTranslate: any,
        performTransliterateSource: any,
        voiceSpeed: any,
        performTts: any,
        performTransliterate: any,
        speakerIdentification: any,
        numberOfSpeakers: any,
        sampleStartSecond: any,
        sampleEndSecond: any,
        sourceLanguage: any,
        targetLanguage: any,
        transliterationModel: any,
        languageDetectionModel: any,
        transcriptionModel: any,
        translationModel: any,
        data: any

    ): Observable<any> {

        const requestBody = {
            sample,
            operation,
            includeTimestamp,
            performTranslate,
            performTransliterateSource,
            voiceSpeed,
            performTts,
            performTransliterate,
            speakerIdentification,
            numberOfSpeakers,
            sampleStartSecond,
            sampleEndSecond,
            sourceLanguage,
            targetLanguage,
            transliterationModel,
            languageDetectionModel,
            transcriptionModel,
            translationModel,
            data,

        };

        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/transcribe`, requestBody, { headers: headers });
            })
        );

    }

    spokenLanguageDetect(data: any): Observable<any> {
        // const data = new FormData();
        // data.append('voicedata', base64);
        // data.append('userID', userID);
        // const headers = {
        // "Authorization": "Bearer " + this.authService.getToken()
        // }
        // return this.http.post(`${this.baseUrl}/inbound/spoken-language-detection`, data, { headers: headers });
        const requestBody = {
            data,

        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/detectLanguage`, requestBody, { headers: headers });
            })
        );

    }
    convertBase64ToBlob(base64: any) {
        let raw = window.atob(base64);
        let rawLength = raw.length;
        let arr = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            arr[i] = raw.charCodeAt(i);
        }
        let blob = new Blob([arr], {
            type: 'audio/webm'
        });
        return blob;
    }



    convertBase64ToBloby(base64: string): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const worker = new Worker('assets/workers/blob-worker.js');
            worker.postMessage(base64);
            worker.onmessage = (event) => {
                resolve(event.data);
                worker.terminate();
            };
            worker.onerror = (error) => {
                reject(error);
                worker.terminate();
            };
        });
    }

    getLanguage() {
        return of(["ASdf"]).pipe(delay(500));
    }


    getVideos() {
        return of(VIDEOS).pipe(delay(250))
    }

    getFormats() {
        return of(FORMATS).pipe(delay(500))
    }

    getYTT(yturl: any, from: string, to: string, member: string): Observable<any> {
        const data = new URLSearchParams();
        data.append('yturl', yturl);
        data.append('from', from);
        data.append('to', to);
        data.append('userID', member);

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.authService.getToken()
        }
        return this.http.post(`${this.baseUrl}/inbound/ytt`, data.toString(), { headers: headers });
    }

    getYoutubeSummary(keywords: any, numVideos: any, timespan: any, translateLanguage: any, textLength: any): Observable<any> {
        // const data = new URLSearchParams();
        // data.append('keywords', keywords);
        // data.append('numVideos', numVideos);
        // data.append('timespan', timespan);
        // data.append('translateLanguage', translateLanguage);
        // data.append('textLength', textLength);
        // data.append('userID', 'API');


        // const headers = {
        //   "Content-Type": "application/x-www-form-urlencoded",
        //   "Authorization": "Bearer " + this.authService.getToken()
        // }

        // return this.http.post(`${this.baseUrl}/inbound/ytsummary`, data.toString(), { headers: headers });

        const requestBody = {
            keywords,
            numVideos,
            timespan,
            translateLanguage,
            textLength,
        };

        const headers = {
            "Authorization": "Bearer " + this.authService.getToken()
        };

        return this.http.post(`${this.baseUrl}/ytsummary`, requestBody, { headers: headers });



    }

    getYTTResponse(uuid: string, member: string): Observable<any> {
        const data = new URLSearchParams();
        data.append('uuid', uuid);
        data.append('userID', member);

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.authService.getToken()
        }
        return this.http.post(`${this.baseUrl}/outbound/yttresponse`, data.toString(), { headers: headers });
    }

    pdfTranslate(data: string, sourceLanguage: string, targetLanguage: string, fileType: string, fileName: string, outputFormat: string, documentFormatModel: string, languageDetectionModel: string, translationModel: string, outputFileName: string): Observable<any> {
        const requestBody = {
            data,
            sourceLanguage,
            targetLanguage,
            fileName,
            outputFormat,
            fileType,
            documentFormatModel,
            languageDetectionModel,
            translationModel,
            outputFileName
        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/documentTranslate`, requestBody, { headers: headers });
            })
        );
    }


    invoiceProcess(data: string, json: string, inputFileName: string, templateData: string, templateFileName: string): Observable<any> {

        const modelName = 'invoice_processing_model_v1'
        const requestBody = {
            data,
            templateData,
            inputFileName,
            templateFileName,
            modelName

        };
        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/extractData`, requestBody, { headers: headers });
            })
        );

    }


    pdfRetirve(uuid: string, member: string): Observable<any> {
        const data = new URLSearchParams();
        data.append('uuid', uuid);
        data.append('userID', member);

        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.authService.getToken()
        }
        return this.http.get(`${this.baseUrl}/outbound/retrievepdf?uuid=${uuid}&userID=${member}`, { headers: headers });
    }

    ocr(
        sourceLanguage: any,
        targetLanguage: any,
        performTranslation: any,
        fileName: any,
        ocrModel: any,
        translationModel: any,
        data: any,
    ): Observable<any> {
        const requestBody = {
            sourceLanguage,
            targetLanguage,
            performTranslation,
            fileName,
            ocrModel,
            translationModel,
            data,

        };

        return this.authService.fetchToken().pipe(
            switchMap(token => {
                const headers = {
                    "Authorization": "Bearer " + token
                };
                return this.http.post(`${this.baseUrl}/ocr`, requestBody, { headers: headers });
            })
        );
    }

    bytesToSize(bytes: any) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return 'n/a';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
        if (i == 0) return bytes + ' ' + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    }

    parseSlugParams(slug: string): Observable<SlugResponse> {
        const slugResponse: SlugResponse = { from: '', to: '', from1: '', to1: '' };

        if (!slug) return of(slugResponse);

        return this.pls.fetchLanguages().pipe(
            map((res) => {
                this.languages = [...res]; // Assuming it resets and sorts the languages
                const fromLower = slug.split('-')[0].toLowerCase();
                const fromLanguage = this.findMatchingLanguage(fromLower);
                if (fromLanguage) slugResponse.from = fromLanguage;
                slugResponse.from1 = fromLower;

                try {                
                const toLower = slug.split('-')[2].toLowerCase()
                const toLanguage = this.findMatchingLanguage(toLower);
                slugResponse.to1 = toLower;
                if (toLanguage) slugResponse.to = toLanguage;
                } catch(Exception) {
                slugResponse.to = '';
                slugResponse.to1 = '';    
                }

                console.log("SLUG", slugResponse);
                return slugResponse;
            })
        );
    }
    
    languages: string[] = [];  // Assuming languages is an array of strings

    public findMatchingLanguage(lowerCaseSlug: string): string | undefined {
        return this.languages.find(language => {
            const firstWordLang = language.split(' ')[0].toLowerCase();
            return firstWordLang.includes(lowerCaseSlug);
        });
    }


    toTitleCase(text: string): string {
        if (text && text != '' && text.length >= 1) {
            text = text.toLowerCase();
            return text.charAt(0).toUpperCase() + text.slice(1);
        }
        return '';
    }

    generativeAi(type: string, value: any, member: string, filename: string): Observable<any> {
        const data = new URLSearchParams();
        data.append('type', type);
        data.append('filename', filename)
        data.append('value', value);
        data.append('userID', member);


        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.authService.getToken()
        }
        return this.http.post(`${this.baseUrl}/inbound/generativeai`, data.toString(), { headers: headers });
    }

    generativeAiDialogue(context: any, question: any, member: string, formuuid: string): Observable<any> {
        const data = new URLSearchParams();
        data.append('context', context);
        data.append('question', question);
        data.append('userID', member);
        data.append('filename', formuuid);


        const headers = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Bearer " + this.authService.getToken()
        }
        return this.http.post(`${this.baseUrl}/inbound/generativeaidialogue`, data.toString(), { headers: headers });
    }




}
