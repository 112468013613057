export const environment = {
  production: true,
  blockConsoleMessages: true,
  baseUrl: 'https://api.genailia.com/api/v2',
  siteKey: '6LdFxBYmAAAAAAy3bahqsFbx4R1F1ilzbLt5lBU8',
  keycloak: {
    authority: 'https://login.genailia.com/',
    redirectUri: 'http://localhost:4200/app',
    postLogoutRedirectUri: 'http://localhost:4200/app',
    realm: 'genzers',
    clientId: 'genailia',
  },
  idleConfig: { idle: 10, timeout: 60, ping: 10 },
};
