import { Component, OnInit, Optional, Inject,Injector, PLATFORM_ID } from '@angular/core';
import { RESPONSE, REQUEST } from '@nguniversal/express-engine/tokens';

import { isPlatformServer } from '@angular/common';
import { Response } from 'express';
import { ServerSideResponseService } from 'src/app/shared/services/server-side-response.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['../../../app.common.scss','./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
	constructor(
			@Optional() @Inject(RESPONSE) private response: Response,@Inject(Injector) private injector: Injector,
			@Optional() @Inject(REQUEST) private request: Request,
			@Inject(PLATFORM_ID) private platformId: Object,
			private ssr: ServerSideResponseService) {
	    this.ssr.setNotFound();
	  }

			ngOnInit() {
			 if(isPlatformServer(this.platformId))//Only executes server side
			     {
			         const response = this.injector.get(RESPONSE) as Response;
			         response.status(404);
			         this.response.status(404);
			     }
			}
}
