import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import {
  NgbAccordionModule,
  NgbCarouselModule,
  NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { SharedModule } from 'src/app/shared/shared.module';
import { ContactComponent } from '../ctas/contact/contact.component';
import { PlatformComponent } from '../resources/platform/platform.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    HomeRoutingModule,
    NgbCarouselModule,
    NgbNavModule,
    NgbAccordionModule,
    NgxTypedJsModule,
    ContactComponent,
    PlatformComponent
    
  ],
  declarations: [HomeComponent],
  exports: [NgbAccordionModule],
})
export class HomeModule {}
