import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ContactUsServiceService {
  private visibility = new BehaviorSubject<boolean>(false);
  isVisible$ = this.visibility.asObservable();

  showPopup() {
    this.visibility.next(true);
  }

  closePopup() {
    this.visibility.next(false);
  }
}
