import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { Location } from '@angular/common';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-platform',
    templateUrl: './platform.component.html',
    standalone: true,
    styleUrls: ['../../../../app.common.scss', './platform.component.scss'],
    imports: [CommonModule]
})
export class PlatformComponent {
    ngOnInit(): void {
        this.myservice.setTitleAndMeta('Platform','Genailia Platform & Components', 'Platform, Components, Conversational AI, Search AI, Experience Accelerators', 'platform');
    }

    constructor(
        public myservice: MyService,
    ) {

    }
    tagCards = ['Model Hub', 'AI Agents', 'File Management', 'Integration Management',]

    tablecards = [
        {
            title: "Agent Experience",
            tags: [
                { name: '' },
                { name: '' },
                { name: '' }
            ]
        }, {
            title: "Customer Experience",
            tags: [
                { name: '' },
                { name: '' },
                { name: '' }
            ]
        }, {
            title: "Employee Experience",
            tags: [
                { name: '' },
                { name: '' },
                { name: '' }
            ]
        }
    ];

    tags0 = [
        {
            name: 'Translation/Transliteration'
        },
        {
            name: 'Transcription/STT/ASR'
        },
        {
            name: 'Text-to-Speech/TTS - 2500+ voices'
        },
        {
            name: 'Voice Cloning'
        },
        {
            name: 'REST API/Socket Transport'
        },
        {
            name: 'Text to Music/SFX'
        },
        {
            name: 'Real Time Voice Translation'
        },
        {
            name: 'Audio/Video Translation'
        }
    ];

    tags1 = [
        {
            name: 'API Integrations'
        },
        {
            name: 'Enterprise tools Integrations'
        },
        {
            name: 'Knowledge Connectors'
        },
        {
            name: 'RAG for any file type'
        },
        {
            name: 'Vector Databases'
        },
        {
            name: 'SQL/NoSQL Integrations'
        }
    ];
    tags2 = [
        {
            name: 'Security'
        },
        {
            name: 'Multi Lingual'
        },
        {
            name: 'Multi Modal'
        },
        {
            name: 'Voice Enabled'
        },
        {
            name: 'Scalability'
        },
        {
            name: 'Collaborative'
        },
        {
            name: 'Analytics'
        }
    ];
    tags3 = [
        {
            name: 'OpenAI GPT 4o'
        },
        {
            name: 'OpenAI Dall-E 3'
        },
        {
            name: 'Llama 3.1'
        },
        {
            name: 'LLaVA'
        },
        {
            name: 'Stable Diffusion'
        },
        {
            name: 'Microsoft Phi-3'
        },
        {
            name: 'Mistral'
        }
    ];
    tags4 = [
        {
            name: 'SaaS, PaaS, AIaaS'
        },
        {
            name: 'On-Prem'
        },
        {
            name: 'Public Cloud'
        },
        {
            name: 'Private VPC Cloud'
        }
    ];


    tableCards2 = [
        {
            tag: "Generative AI",
            tagCards: ["Chatbots", "Generation", "Conversations"]  // Array of strings representing additional tags for element D
        },
        {
            tag: "Language Interfaces",
            tagCards: ["Translation", "TTS", "OCR", "Transcription"]  // Array of strings for element E
        },
        {
            tag: "Experience Accelerators",
            tagCards: ["Customer", "Agent", "Staff"]  // Array of strings for element F
        },
        {
            tag: "Search AI",
            tagCards: ["RAG/LLM Based", "Generation", "Extraction"]  // Array of strings for element F
        }
    ];


}
