import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { GlossaryDataService } from '../glossary-data.service';
import { RouterModule } from '@angular/router';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-glossary-details',
    standalone: true,
    imports: [CommonModule, RouterModule],
    templateUrl: './glossary-details.component.html',
    styleUrls: ['./glossary-details.component.scss']
})
export class GlossaryDetailsComponent {

    route: ActivatedRoute = inject(ActivatedRoute)

    glossaryService = inject(GlossaryDataService)
    keywordDetails: any | undefined;
    keyword: string = '';
    constructor(
        private myservice: MyService
    ) {
        const _id = this.route.snapshot.params["_id"]
        this.keyword = _id;
        this.keywordDetails = this.glossaryService.getKewordById(_id)
    }
    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Glossary - " + this.keywordDetails.keyword, this.keywordDetails.keyword + ":" + this.keywordDetails.description + " - The Genailia Glossary provides definitions of key terms related to AI, machine learning & NLP, helping users to better understand the technology.", "AI Ethics,Artificial Neural Network,Unsupervised Learning,Reinforcement Learning,Computer Vision,Natural Language Processing,Deep Learning,Neural Networks,Machine Learning,Glossary,AI Terminology, Industry, best practices", "NA");
    }
}
