<div class="container py-4 about">
  <div class="about-heading my-4">
    <div class="about-heading-hyphon"></div>
    <div class="about-heading-text">ABOUT</div>
    <div class="about-heading-ai-text">US</div>
  </div>

  <div class="about-content">
    <p>
      We are <strong>Genailia</strong> - Your trusted partner in AI transformations. Founded in 2021, we identified a significant gap in the market for reliable AI solutions tailored to startups, SMEs, and Enterprises alike. Our mission is to bridge this gap by offering high-quality, scalable services designed to fuel your business growth.
    </p>
    <p><br/>
      Today, AI is breaking barriers, and we're at the forefront of this exciting journey. Our goal is to empower businesses to fully harness AI's potential, enabling effortless adaptation to ever-changing market needs. Our experts work closely with you to understand your vision, while our engineers craft tailor-made AI solutions that drive success - from development to automation.
    </p>
    <p><br/>
      Ready for a transformation? Fast-track your business with a free consultation call. Discover the perfect AI solution tailored for you!
                            <br/><br/><link
                                href="https://assets.calendly.com/assets/external/widget.css"
                                rel="stylesheet" />
                            <a href=""
                                onclick="Calendly.initPopupWidget({url: 'https://calendly.com/genailia'});return false;"
                                class="btn btn-neutral btn-icon d-block w-fit mx-auto"
                                data-aos="fade-up" data-aos-delay="150">
                                <i class="fa-regular fa-calendar mr-2"></i> Schedule
                                a call with our experts
                            </a><br/><br/>
    </p>
    <p>
      Elevate your customer service with our <strong>eLLMo Chatbot</strong>. We prioritize privacy - deploying self-contained conversational AI that operate seamlessly both offline and online, giving you full control over your data.
    </p>    <p><br/>
      In an age where customer experience is paramount, we offer multi-modal, multi-lingual, and voice-enabled chatbots that don't just interact - they engage. These chatbots are powered by advanced generative AI, capable of providing personalized responses that are both accurate and context-aware, all while ensuring your data remains secure.
    </p>
  </div>
</div>

<div class="container py-4 about">
  <div class="about-heading my-4">
    <div class="about-heading-hyphon"></div>
    <div class="about-heading-text">VISION</div>
  </div>

  <div class="about-content">
    <p>
      Our vision is to not only revolutionize industries but also to empower businesses with cutting-edge generative AI, conversational AI, and Linguistic AI technologies. We aim to unlock limitless creative potential by providing intuitive, multilingual voice and text interfaces that make accessing and utilizing information as effortless as possible. Through our innovative approach, we aspire to set new benchmarks for AI-driven transformation, making technology a seamless extension of human capability.
    </p>
  </div>
</div>

<div class="container py-4 about">
  <div class="about-heading my-4">
    <div class="about-heading-hyphon"></div>
    <div class="about-heading-text">MISSION</div>
  </div>

  <div class="about-content">
    <p>
      At <strong>Genailia</strong>, our mission is to lead the AI revolution by specializing in generative AI and conversational AI solutions that are tailored to meet the unique challenges of our clients. We are committed to delivering solutions that not only meet but exceed customer expectations, setting new standards for innovation and excellence in the AI industry. By attracting and nurturing top talent, we aim to create a dynamic and collaborative environment that fosters growth, creativity, and success.
    </p>
  </div>
</div>

