import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: [ './terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
    
  constructor(private myservice:MyService) {
      this.myservice.setTitleAndMeta("Terms and Conditions","Genailia's Terms and Conditions","terms and conditions, privacy poicy, payment terms, return policy, refund policy, T&C, cookie policy, GDPR, data collection, cookies, storage policy, EULA, legal aspects, law","terms-and-conditions");
  }
}
