import { Component, ViewEncapsulation } from '@angular/core';
import { FEATURES, LINGO } from 'src/app/shared/constants/navbar';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-lingo',
    templateUrl: './lingo.component.html',
    styleUrls: ['../../../app.common.scss', './lingo.component.scss'],
    encapsulation: ViewEncapsulation.Emulated // or ShadowDom
})
export class LingoComponent {

    features = FEATURES;
    lingo = [
        {
            "title": "100 Languages",
            "description": "Lingo supports over 100 languages, enabling seamless communication across global audiences through advanced AI-driven translation, transcription, and text-to-speech capabilities."
        },
        {
            "title": "Text to Speech",
            "description": "Convert written text into natural-sounding speech with over 2,500 voices, covering a wide range of languages and accents to enhance user experiences and accessibility."
        },
        {
            "title": "Transcription/Speech to Text",
            "description": "Accurately transcribe spoken language into text with timestamping, speaker identification, and the ability to handle multiple languages and dialects."
        },
        {
            "title": "Audio/Video Translation",
            "description": "Translate audio and video content in real time, making it accessible to multilingual audiences by converting spoken language into their desired language."
        },
        {
            "title": "Document Translation",
            "description": "Translate entire documents quickly and accurately, supporting a wide variety of file formats, including PDFs, Word documents, and rich text formats."
        },
        {
            "title": "Language Detection",
            "description": "Automatically detect the language of both text and spoken content, providing insights into multilingual data and streamlining translation workflows."
        },
        {
            "title": "Emotional TTS",
            "description": "Bring emotional nuance to speech with emotional Text-to-Speech (TTS), enhancing user interactions by delivering expressive and dynamic voice responses."
        },
        {
            "title": "Optical Character Recognition",
            "description": "Extract text from images and scanned documents through Optical Character Recognition (OCR), enabling translation, transcription, and text analysis of visual content."
        },
        {
            "title": "NLP Tasks",
            "description": "Perform various NLP tasks such as classification, summarization, sentiment analysis on the extracted language using the various features of Lingo."
        }

    ];



    data = [
        'search with ai',
        'llm based q&a model',
        'llm based paas ai',
        'large language model based api',
        'llm based generative ai search',
        'generative ai models',
        'small language model',
        'small LLM',
        'llm vector search',
        'llm based entity recognition with prompt',
        'prompt sentiment analysis with generative ai',
        'best large language model ai',
        'understanding large language models',
        'large language models overview',
        'large language models gpt',
        'private gpt',
        'private llm',
        'train your own model',
        'fine-tuning large language models',
        'train your own language model',
        'ai for operations',
        'ai in enterprise',
        'ai at workplace',
        'llm/gpt llm for workplace',
        'llm/gpt for enterprise',
        'llm/gpt for enterprise',
        'llm/gpt for organization',
        'ai for language',
        'language gpt',
        'GPT in other language',
        'gpt in native language',
        'search audio/video with llm'
    ];

    getIconByIndex(index: number): string {
        const iconMap = [
            'globe',            // for "100 Languages"
            'volume-up',        // for "Text to Speech"
            'microphone',       // for "Transcription/Speech to Text"
            'film',             // for "Audio/Video Translation"
            'file-alt',         // for "Document Translation"
            'language',         // for "Language Detection"
            'grin',             // for "Emotional TTS"
            'file-image',       // for "Optical Character Recognition"
            'tasks'             // for "NLP Tasks"
        ];
        return iconMap[index - 1] || 'question-circle'; // Default to question icon if out of bounds
    }

    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Lingo", "Lingo is Linguistic AI module - use Lingo for various language tasks such as OCR, Language, Translation, Transcription, OCR, Language Detection, TTS etc.", "Translation, Transcription, TTS, OCR, Language Detection, Language", "lingo");
    }

    constructor(
        private myservice: MyService,
    ) {


    }

    getWordsByIndex(index: number): string {
        const startIndex = index * 2;
        const endIndex = startIndex + 1;

        if (startIndex < 0 || endIndex >= this.data.length) {
            return "Invalid index";
        }

        const word1 = this.data[startIndex];
        const word2 = this.data[endIndex];

        return `${word1} ${word2}`;
    }

}
