import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['../../../../app.common.scss', './partners.component.scss']
})
export class PartnersComponent {

    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Partners", "Partner with us - Discover partnership opportunities with Genailia. Collaborate with us to drive innovation in AI, NLP, and language services.", "about us, all about genailia, team, careers, partnerships, our thesis, vision, mission, people, company, investors, associations", "partners");
    }

    constructor(
        private myservice: MyService,
    ) {
    }

}
