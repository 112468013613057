<div class="container py-4 careers">
  <div class="careers-heading my-4">
    <div class="careers-heading-hyphon"></div>
    <div class="careers-heading-text">Careers</div>
  </div>

  <div class="careers-content">
    <p>
      At Genailia, we are passionate about driving innovation and making a difference with cutting-edge AI solutions. We believe that our people are our greatest asset, and we are committed to creating a dynamic, inclusive, and empowering workplace where creativity and collaboration thrive.
    </p>
    
    <p>
    <br/>
      Working with us means being part of a fast-growing company that values innovation, creativity, and dedication. Our team is made up of talented individuals from diverse backgrounds, all working together to push the boundaries of what's possible with AI.
    </p>
    
    <p><br/>
      We are always on the lookout for driven and talented professionals who are eager to grow and make an impact. Whether you're a seasoned expert or just starting out, you'll find opportunities to challenge yourself and advance your career.
    </p>
    
    <p><br/>
      Ready to take the next step in your career? Join us at Genailia and be a part of something extraordinary. Together, we'll shape the future of AI.
    </p>
    
    <p><br/>
      <a class="btn btn-primary btn-outline-info rounded-pill" href="mailto:contact@genailia.com">Contact Us</a> to learn more about career opportunities and how you can contribute to our mission.
    </p>
  </div>
</div>
