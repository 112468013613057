<div class="container py-4 commn">
    <div class="commn-heading mt-4">
        <div class="commn-heading-hyphon"></div>
        <div class="commn-heading-text">
            <br>
            <br>
            <h3>E-book</h3>
            <p *ngIf="!isContactFormSubmitted">To get the e-book, please fill the below form</p>
        </div>

        <div *ngIf="!isContactFormSubmitted" class="container custom-margin">
            <div class="row">
              <div
                class="col-12 col-md-5"
                data-aos="fade-right"
                data-aos-duration="1500"
                data-aos-offset="200"
              >
                <div class="icon-box p-3 text-purple font-weight-light">
                  <i class="fa-solid fa-envelope add2"></i>
                </div>
                <h3 class="mb-0 font-weight-light lh-1 mt-2 pb-4 pb-md-0">Contact us</h3>
          
                <div class="d-none d-md-block">
                  <p class="font-weight-bold mt-3 mb-0">Address</p>
                  <p class="text-sm mb-0">Floor 3, T-Hub,</p>
                  <p class="mb-0 text-sm">Plot No 1/C, Sy No 83/1,</p>
                  <p class="mb-0 text-sm">Hyderabad Knowledge City,</p>
                  <p class="mb-0 text-sm">Serilingamaplly, Hyderabad,</p>
                  <p class="mb-0 text-sm">Telangana - 500081</p>
          
                  <p class="font-weight-bold mt-3 mb-0">Social media</p>
                  <div class="d-flex align-items-center custom-gap">
                    <a
                      target="_blank"
                      rel="noreferrer noopener"
                      [href]="network.link"
                      class="h3 add2"
                      *ngFor="let network of socialNetworks"
                    >
                      <i [ngClass]="network.class"></i
                    ></a>
                  </div>
                </div>
              </div>
          
              <div
                class="col-12 col-md-7"
                id="contact-anchor2"
                [attr.data-aos]="responsiveFade"
                data-aos="fade-left"
                data-aos-duration="1500"
                data-aos-offset="200"
              >
                <form class="row" (ngSubmit)="onSubmit()" [formGroup]="contactForm">
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput11">Name</label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        id="exampleFormControlInput11"
                        placeholder="Your name"
                        formControlName="name"
                        [class.is-invalid]="
                          name?.invalid && (name?.dirty || name?.touched)
                        "
                        [class.is-valid]="name?.valid"
                      />
                      <div
                        *ngIf="name?.invalid && (name?.dirty || name?.touched) && name?.errors?.['required']"
                        class="text-start invalid-feedback"
                      >
                        Name is required.
                      </div>
                      <div
                        *ngIf="name?.invalid && (name?.dirty || name?.touched) && name?.errors?.['minlength']"
                        class="text-start invalid-feedback"
                      >
                        Minimum
                        {{name?.errors?.['minlength']['requiredLength']}} character is
                        required.
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput12">Email</label>
                      <input
                        type="email"
                        class="form-control form-control-alternative"
                        id="exampleFormControlInput12"
                        placeholder="name@example.com"
                        formControlName="email"
                        [class.is-invalid]="
                          email?.invalid && (email?.dirty || email?.touched)
                        "
                        [class.is-valid]="email?.valid"
                      />
                      <div
                        *ngIf="email?.invalid && (email?.dirty || email?.touched) && email?.errors?.['required']"
                        class="text-start invalid-feedback"
                      >
                        Email is required.
                      </div>
                      <div
                        *ngIf="email?.invalid && (email?.dirty || email?.touched) && email?.errors?.['email']"
                        class="text-start invalid-feedback"
                      >
                        Email is invalid.
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput13">Designation</label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        id="exampleFormControlInput13"
                        placeholder="Your designation"
                        formControlName="designation"
                        [class.is-invalid]="
                          designation?.invalid &&
                          (designation?.dirty || designation?.touched)
                        "
                        [class.is-valid]="designation?.valid"
                      />
                      <div
                        *ngIf="designation?.invalid && (designation?.dirty || designation?.touched) && designation?.errors?.['required']"
                        class="text-start invalid-feedback"
                      >
                        Designation is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label for="exampleFormControlInput14">Company</label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        id="exampleFormControlInput14"
                        placeholder="Your company"
                        formControlName="company"
                        [class.is-invalid]="
                          company?.invalid && (company?.dirty || company?.touched)
                        "
                        [class.is-valid]="company?.valid"
                      />
                      <div
                        *ngIf="company?.invalid && (company?.dirty || company?.touched) && company?.errors?.['required']"
                        class="text-start invalid-feedback"
                      >
                        Company is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1"
                        >How did you hear about us?</label
                      >
                      <select
                        class="form-control form-control-alternative"
                        id="exampleFormControlSelect1"
                        formControlName="heard"
                        [class.is-invalid]="
                          heard?.invalid && (heard?.dirty || heard?.touched)
                        "
                        [class.is-valid]="heard?.valid"
                      >
                        <option [selected]="true" disabled value="1">
                          --Choose Category--
                        </option>
                        <option>Social media</option>
                        <option>LinkedIn</option>
                        <option>Facebook</option>
                        <option>Prefer not to say</option>
                      </select>
                      <div
                        *ngIf="heard?.invalid && (heard?.dirty || heard?.touched) && heard?.errors?.['required']"
                        class="text-start invalid-feedback"
                      >
                        This field is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput15">Subject</label>
                      <input
                        type="text"
                        class="form-control form-control-alternative"
                        id="exampleFormControlInput15"
                        placeholder="Your subject"
                        formControlName="subject"
                        [class.is-invalid]="
                          subject?.invalid && (subject?.dirty || subject?.touched)
                        "
                        [class.is-valid]="subject?.valid"
                      />
                      <div
                        *ngIf="subject?.invalid && (subject?.dirty || subject?.touched) && subject?.errors?.['required']"
                        class="text-start invalid-feedback"
                      >
                        Subject is required.
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label for="exampleFormControlInput16">Message</label>
                      <textarea
                        class="form-control form-control-alternative"
                        id="exampleFormControlInput16"
                        rows="3"
                        placeholder="Please enter your message here"
                        formControlName="message"
                        [class.is-invalid]="
                          message?.invalid && (message?.dirty || message?.touched)
                        "
                        [class.is-valid]="message?.valid"
                        [maxlength]="maxChars"
                      ></textarea>
                      <div
                        *ngIf="message?.invalid && (message?.dirty || message?.touched) && message?.errors?.['required']"
                        class="text-start invalid-feedback"
                      >
                        Message is required.
                      </div>
                    </div>
                  </div>
          
                  <div class="col-12 text-right">
                    <button type="submit" class="btn btn-primary btn-icon">
                      Send
                      <i class="fa-regular fa-paper-plane ml-2"></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
        </div>
        <div *ngIf="isContactFormSubmitted">
            <embed src="/assets/e-book/Generative-AI-for-enterprises.pdf" type="application/pdf" width="100%" height="600px" />
        </div>
    </div>
       
</div>