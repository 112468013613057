<div class="container py-4 refund">
    <div>
        <h1 class="refund-heading-text">Refund Policy</h1>
    </div>
    <h2 class="refund-subheading-text">v1.1 - Last Edited 19th August
        2024</h2>
    <div class="refund-content">
        <h2 class="refund-subheading-text">1. Introduction</h2>
        <p>At Genailia/GenZ Technologies Pvt Ltd, we strive to ensure that our customers are satisfied with our services. This Refund Policy outlines the conditions under which you may request a refund for services purchased through our Platform, including SaaS subscriptions and other digital services. By using our services, you agree to the terms outlined in this policy.</p>

        <h2 class="refund-subheading-text">2. Eligibility for Refunds</h2>
        <p>Refunds may be issued under the following circumstances:</p>
        <ul>
            <li><strong>Service Downtime:</strong> If our services are unavailable for a significant period due to issues on our end, you may be eligible for a partial refund, depending on the length of the downtime.</li>
            <li><strong>Billing Errors:</strong> If you are charged incorrectly, such as being billed twice for the same service, you are entitled to a full refund of the overcharged amount.</li>
            <li><strong>Service Non-Delivery:</strong> If you have paid for a service that was not delivered as agreed upon, you may request a refund for that specific service.</li>
            <li><strong>Trial Periods:</strong> If you were on a free trial and were incorrectly charged, you may request a full refund.</li>
        </ul>

        <h2 class="refund-subheading-text">3. Non-Refundable Situations</h2>
        <p>Refunds will not be issued under the following circumstances:</p>
        <ul>
            <li><strong>Cancellation of Subscription:</strong> If you cancel your subscription during a billing cycle, you will not be eligible for any refund.</li>
            <li><strong>Change of Mind:</strong> Refunds will not be provided if you simply change your mind after purchasing a service.</li>
            <li><strong>Partial Usage:</strong> If you have used a significant portion of the service before requesting a refund, you will not be eligible for a refund for that billing cycle.</li>
            <li><strong>Technical Issues:</strong> Refunds will not be issued for technical issues unless the problem is caused by our platform and cannot be resolved by our support team.</li>
            <li><strong>Violation of Terms:</strong> If your account is suspended or terminated due to a violation of our terms and conditions, no refund will be issued.</li>
        </ul>

        <h2 class="refund-subheading-text">4. How to Request a Refund</h2>
        <p>To request a refund, please follow these steps:</p>
        <ul>
            <li>Contact our support team at <a href="mailto:connect@genzers.tech">connect&#64;genzers.tech</a> with the subject line "Refund Request."</li>
            <li>Provide your account details, the service in question, and the reason for the refund request.</li>
            <li>Include any relevant transaction details, such as the invoice number or payment reference number.</li>
        </ul>
        <p>Our team will review your request and respond within 7 business days. If approved, the refund will be processed within 10-15 business days, depending on your payment method.</p>

        <h2 class="refund-subheading-text">5. Refund Processing</h2>
        <p>Approved refunds will be processed using the original payment method. If the original payment method is no longer available (e.g., a closed credit card), you must provide an alternative method for the refund.</p>
        <p>Please note that it may take additional time for the refunded amount to appear in your account, depending on your bank or payment provider's policies.</p>

        <h2 class="refund-subheading-text">6. Currency and Exchange Rates</h2>
        <p>All refunds will be issued in the original currency of the transaction. If you made a payment in a currency other than your local currency, the refunded amount may differ due to fluctuations in exchange rates. Genailia/GenZ Technologies Pvt Ltd is not responsible for any differences in the refunded amount caused by exchange rate changes.</p>

        <h2 class="refund-subheading-text">7. Subscription Cancellations and Refunds</h2>
        <p>If you cancel your subscription, your access to the service will continue until the end of the current billing cycle. No refunds will be issued for the remaining days of the billing cycle unless stated otherwise in this policy.</p>
        <p>If you qualify for a refund based on the eligibility criteria mentioned above, the refund will be processed as outlined in this policy.</p>

        <h2 class="refund-subheading-text">8. Contact Us</h2>
        <p>If you have any questions or concerns about this Refund Policy, please contact us at:</p>
        <ul>
            <li>Email: <a href="mailto:connect@genzers.tech">connect&#64;genzers.tech</a></li>
            <li>Address: GenZ Technologies Pvt Ltd, Floor 3, T-Hub, Plot No 1/C, Sy No 83/1, Hyderabad Knowledge City, Serilingampally, Hyderabad, Telangana - 500081, India</li>
        </ul>
        <p>Our team is here to assist you and ensure that your experience with our services meets your expectations.</p>
    </div>
</div>
