<div class="container-max-width">



	<!-- Lingo Section -->
	<section class="section-custom-layout">
		<div class="image-container" data-aos="fade-left">
			<img loading="lazy" src="../../assets/images/lingo.webp"
				alt="Lingo Linguistic AI Module" />
		</div>

		<div class="section-custom-content">
			<div class="section-custom-text" data-aos="fade-right">
				<h2>
					Mastering Multilingual Communication, <strong>Discover the
						Power of Lingo</strong>
				</h2>
				<p>
					Lingo, short for Linguistic Interfaces' Next Generation Operations, is the comprehensive <b>Linguistic AI module</b> within
					Genailia, offering seamless integration of language technologies.
					With support for over <b>100 languages</b>, Lingo enables precise <b>translation</b>,
					<b>transcription</b>, and <b>text-to-speech (TTS)</b>
					functionalities. Its advanced <b>emotional TTS</b> and <b>voice
						cloning</b> capabilities bring a human touch to AI-driven
					interactions. <br /> Lingo supports real-time features like <b>live
						transcript and live transcript translation</b>, and enables businesses
					to scale with rich text, video, audio, and document translation.
					From <b>OCR</b> to <b>speaker identification</b>, Lingo is a
					one-stop solution for enhancing communication and user experience
					across industries.
				</p>
			</div>
		</div>

	</section>

	<br />
	<br />
	<br />
	<br />


<div class="container-full-width">
    <h5 class="heading-custom" data-aos="fade-up">
        Lingo <span>Features</span>
    </h5>
    <div class="grid-container">
        <div *ngFor="let card of lingo; let index = index"
            class="card-container" data-aos="fade-up"
            [attr.data-aos-delay]="100 * index" data-aos-offset="50">
            <div>
                <i [class]="'fa fa-' + getIconByIndex(index+1) + ' fa-4x'" aria-hidden="true"></i>
            </div>
            <div class="card-content">
                <h3>{{ card.title }}</h3>
                <p>{{ card.description }}</p>
            </div>
        </div>
    </div>
</div>


</div>



