import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-investors',
    templateUrl: './investors.component.html',
    styleUrls: ['../../../../app.common.scss', './investors.component.scss']
})
export class InvestorsComponent {

    ngOnInit(): void {
        this.myservice.setTitleAndMeta("Investors", "Learn about investment opportunities with Genailia. Explore our growth potential in AI, NLP, and language services.", "about us, all about genailia, team, careers, partnerships, our thesis, vision, mission, people, company, investors, associations", "investors");
    }

    constructor(
        private myservice: MyService,
    ) {
    }
    
}
