import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';

@Component({
  selector: 'app-ai-capabilities',
  templateUrl: './ai-capabilities.component.html',
  styleUrls: ['../../../../app.common.scss','./ai-capabilities.component.scss']
})
export class AiCapabilitiesComponent {
    constructor(private myservice: MyService
    ) {
        this.myservice.setTitleAndMeta("AI Capabilities", "Our AI Capabilities - Our Capabilities = Your Transformations", "ai transformations, capabilities", "ai-capabilities");
}
  }
