<ng-template #idleModal let-c="close" let-d="dismiss">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">You Have Been Idle!</h4>
        <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
    </div>
    <div class="modal-body">
        <p>{{idleState}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="stay()" class="btn btn-success"><i class="bi bi-stopwatch-fill"></i> Continue</button>
        <button type="button" (click)="logout()" class="btn btn-warning"><i class="bi bi-box-arrow-right"></i> Logout</button>
    </div>
</ng-template>
<app-contact-us-popup></app-contact-us-popup>
<router-outlet></router-outlet>