import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';

@Component({
  selector: 'app-payment-terms',
  standalone: true,
  templateUrl: './payment-terms.component.html',
  styleUrls: ['./payment-terms.component.scss']
})
export class PaymentTermsComponent {
    
  constructor(private myservice:MyService) {
      this.myservice.setTitleAndMeta("Payment Terms","Genailia's Payment Terms and Conditions.","terms and conditions, privacy poicy, payment terms, return policy, refund policy, T&C, cookie policy, GDPR, data collection, cookies, storage policy, EULA, legal aspects, law","payment-terms");
  }

}
