import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FaqComponent } from '../common/components/resources/faq/faq.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [FaqComponent],
  imports: [CommonModule,FormsModule,NgbModule],
  exports: [FaqComponent],
})
export class FAQModule {}
