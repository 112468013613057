import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router  } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-cmp',
  templateUrl: './cmp.component.html',
  styleUrls: ['./cmp.component.scss']
})
export class CmpComponent implements OnInit{
  id:string = '';
  type:string = '';
  constructor(private route: ActivatedRoute,private router: Router,private authservice:AuthService) {}

  ngOnInit(): void {
    // Retrieve parameters from the URL
    this.route.queryParams.subscribe((params) => {
       this.id = params['id'];
      this.type = params['type'];

      // Now you can use id and type as needed
      console.log('ID:', this.id);
      console.log('Type:', this.type);


      this.authservice.cmpGet("","",this.id,this.type).subscribe(res=>{
      console.log("captured successfully");
      this.router.navigate(['/home']);
      })
    });
  }

}
