import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsServiceService } from '../contact-us-service.service';
import { ContactComponent } from '../contact/contact.component';
@Component({
  selector: 'app-contact-us-popup',
  standalone: true,
  imports: [CommonModule,ContactComponent],
  templateUrl: './contact-us-popup.component.html',
  styleUrls: ['./contact-us-popup.component.scss']
})
export class ContactUsPopupComponent {
  isVisible = false;

  constructor(private contactUsService: ContactUsServiceService) {}

  ngOnInit(): void {
    this.contactUsService.isVisible$.subscribe((isVisible) => {
      this.isVisible = isVisible;
    });
  }

  closePopup() {
    this.contactUsService.closePopup();
  }

}
