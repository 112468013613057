import { Component } from '@angular/core';

@Component({
  selector: 'app-usecases',
  templateUrl: './usecases.component.html',
  styleUrls: ['../../../../app.common.scss','./usecases.component.scss']
})
export class UsecasesComponent {	
  constructor() {
  }

}
