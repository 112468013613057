<!-- <div class="container py-4 contact">
    <div class="mt-4">
        <div class="mt-4">
            <div class="row">
                <div class="col md-4 text-sm-center text-md-start">
                    <div class="contact-heading mb-2">
                        <div class="contact-heading-hyphon"></div>
                        <div class="contact-heading-text">
                            <span>Contact</span>
                            <span class="contact-heading-ai-text"> Us</span>
                        </div>
                    </div>
                    <div class="mb-4">
                        <h4 class="text-secondary">Address</h4>
                        <div>
                            <div>Floor 3, T-Hub,</div>
                            <div>Plot No 1/C, Sy No 83/1,</div>
                            <div>Hyderabad Knowledge City,</div>
                            <div>Serilingamaplly, Hyderabad,</div>
                            <div>Telangana - 500081</div>
                        </div>
                    </div>
                    <div class="mb-2">
                        <h5 class="text-secondary">Social Media</h5>
                        <div class="social-media-icons d-flex gap-3 social-media-icons">
                            <a aria-label="Contact us through LinkedIn" class="linkedin" href="https://www.linkedin.com/company/genailia" target="_blank" rel="noopener">
                                <i class="bi bi-linkedin"></i>
                            </a>
                            <a aria-label="Checkout our YouTube Channel" class="youtube" href="https://www.youtube.com/@genailia" rel="noopener noreferrer" target="_blank">
                                <i class="bi bi-youtube"></i>
                            </a>
			                <a aria-label="Tweet to Us!" class="twitter" href="https://twitter.com/genailia" target="_blank" rel="noopener noreferrer">
			                    <i class="bi bi-twitter"></i>
			                </a>
			                <a aria-label="Reach us on Facebook" class="facebook" href="https://facebook.com/genzersdotai" target="_blank" rel="noopener noreferrer">
			                    <i class="bi bi-facebook"></i>
			                </a>
			                <a aria-label="Our Instagram Reels" class="instagram" href="https://www.instagram.com/genzers.ai/" target="_blank" rel="noopener noreferrer">
			                    <i class="bi bi-instagram"></i>
			                </a>                            
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-md-12">
                        </div>
                    </div>
                </div>
                <div class="col-md-8">
                    <hr class="d-md-none">
                    <ngb-alert class="w-100" type="danger" (closed)="error = null" *ngIf="error">
                        <div>{{error}}</div>
                    </ngb-alert>

                    <ngb-alert class="w-100" type="success" (closed)="success = null" *ngIf="success">
                        <div><strong>Success!</strong> {{success}}</div>
                    </ngb-alert>
                    <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row mb-2">
                            <div class="col-md-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" formControlName="name" class="form-control is-invalid" id="name"
                                        [class.is-invalid]="name?.invalid && (name?.dirty || name?.touched)"
                                        [class.is-valid]="name?.valid" placeholder="Enter the name">
                                    <label for="name">Name</label>
                                    <div *ngIf="name?.invalid && (name?.dirty || name?.touched) && name?.errors?.['required']"
                                        class="text-start invalid-feedback">
                                        Name is required.
                                    </div>
                                    <div *ngIf="name?.invalid && (name?.dirty || name?.touched) && name?.errors?.['minlength']"
                                        class="text-start invalid-feedback">
                                        Minimum {{name?.errors?.['minlength']['requiredLength']}} character is
                                        required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="email" formControlName="email" class="form-control" id="email"
                                        [class.is-invalid]="email?.invalid && (email?.dirty || email?.touched)"
                                        [class.is-valid]="email?.valid" placeholder="name@example.com">
                                    <label for="email">Email</label>
                                    <div *ngIf="email?.invalid && (email?.dirty || email?.touched) && email?.errors?.['required']"
                                        class="text-start invalid-feedback">
                                        Email is required.
                                    </div>
                                    <div *ngIf="email?.invalid && (email?.dirty || email?.touched) && email?.errors?.['email']"
                                        class="text-start invalid-feedback">
                                        Email is invalid.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-6 mb-2">
                                <div class="form-floating">
                                    <input type="text" formControlName="designation" class="form-control"
                                        id="designation"
                                        [class.is-invalid]="designation?.invalid && (designation?.dirty || designation?.touched)"
                                        [class.is-valid]="designation?.valid" placeholder="Designation">
                                    <label for="designation">Designation</label>
                                    <div *ngIf="designation?.invalid && (designation?.dirty || designation?.touched) && designation?.errors?.['required']"
                                        class="text-start invalid-feedback">
                                        Designation is required.
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-floating">
                                    <input type="text" formControlName="company" id="company" class="form-control"
                                        [class.is-invalid]="company?.invalid && (company?.dirty || company?.touched)"
                                        [class.is-valid]="company?.valid" placeholder="Company">
                                    <label for="company">Company</label>
                                    <div *ngIf="company?.invalid && (company?.dirty || company?.touched) && company?.errors?.['required']"
                                        class="text-start invalid-feedback">
                                        Company is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-floating mb-2">
                            <input type="subject" formControlName="subject" class="form-control" id="subject"
                                [class.is-invalid]="subject?.invalid && (subject?.dirty || subject?.touched)"
                                [class.is-valid]="subject?.valid" placeholder="subject">
                            <label for="subject">Subject</label>
                            <div *ngIf="subject?.invalid && (subject?.dirty || subject?.touched) && subject?.errors?.['required']"
                                class="text-start invalid-feedback">
                                Subject is required.
                            </div>
                        </div>
 
                        <div class="form-group mb-2">
                            <textarea rows="5" formControlName="message" class="form-control" id="message"
                                [class.is-invalid]="message?.invalid && (message?.dirty || message?.touched)"
                                [class.is-valid]="message?.valid" [maxlength]="maxChars"
                                placeholder="Please enter your message here"></textarea>
                            <div *ngIf="message?.invalid && (message?.dirty || message?.touched) && message?.errors?.['required']"
                                class="text-start invalid-feedback">
                                Message is required.
                            </div>
                            <div *ngIf="message?.valid" class="text-end text-secondary">{{message?.value?.length}} / {{maxChars}}</div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center g-recaptcha">
                            <button class="btn btn-lg btn-success" type="submit" [disabled]="contactForm.invalid">
                                <i class="bi bi-send-fill"></i> Send <span *ngIf="isLoading"
                                    class="me-1 spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->

<section class="container custom-margin">
  <div class="row">
    <div
      class="col-12 col-md-5"
      data-aos="fade-right"
      data-aos-duration="1500"
      data-aos-offset="200"
    >
      <!-- <div class="icon-box p-3 text-purple font-weight-light">
        <i class="fa-solid fa-envelope add2"></i>
      </div> -->
      <h3 class="mb-0 font-weight-light lh-1 mt-2 pb-4 pb-md-0">Contact us</h3>

      <div class="d-none d-md-block">
        <p class="font-weight-bold mt-3 mb-0">Address</p>
        <p class="text-sm mb-0">Floor 3, T-Hub,</p>
        <p class="mb-0 text-sm">Plot No 1/C, Sy No 83/1,</p>
        <p class="mb-0 text-sm">Hyderabad Knowledge City,</p>
        <p class="mb-0 text-sm">Serilingamaplly, Hyderabad,</p>
        <p class="mb-0 text-sm">Telangana - 500081</p>

        <p class="font-weight-bold mt-3 mb-0">Social media</p>
        <div class="d-flex align-items-center custom-gap">
          <a
            target="_blank"
            rel="noreferrer noopener"
            [href]="network.link"
            class="h3 add2"
            *ngFor="let network of socialNetworks"
          >
            <i [ngClass]="network.class"></i
          ></a>
        </div>
      </div>
    </div>
    <div class="modal fade" [ngClass]="{'show': showModal}" [ngStyle]="{'display': showModal ? 'block' : 'none'}">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Thank You</h5>
            <button type="button" class="close" (click)="closeModal()">
              <span>&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Thank you for contacting us! We will get back to you shortly.</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 col-md-7"
      id="contact-anchor2"
      [attr.data-aos]="responsiveFade"
      data-aos="fade-left"
      data-aos-duration="100"
      data-aos-offset="80"
    >
      <form class="row" (ngSubmit)="onSubmit()" [formGroup]="contactForm">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput11">Name</label>
            <input
              type="text"
              class="form-control form-control-alternative"
              id="exampleFormControlInput11"
              placeholder="Your name"
              formControlName="name"
              [class.is-invalid]="
                name?.invalid && (name?.dirty || name?.touched)
              "
              [class.is-valid]="name?.valid"
            />
            <div
              *ngIf="name?.invalid && (name?.dirty || name?.touched) && name?.errors?.['required']"
              class="text-start invalid-feedback"
            >
              Name is required.
            </div>
            <div
              *ngIf="name?.invalid && (name?.dirty || name?.touched) && name?.errors?.['minlength']"
              class="text-start invalid-feedback"
            >
              Minimum
              {{name?.errors?.['minlength']['requiredLength']}} character is
              required.
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput12">Email</label>
            <input
              type="email"
              class="form-control form-control-alternative"
              id="exampleFormControlInput12"
              placeholder="name@example.com"
              formControlName="email"
              [class.is-invalid]="
                email?.invalid && (email?.dirty || email?.touched)
              "
              [class.is-valid]="email?.valid"
            />
            <div
              *ngIf="email?.invalid && (email?.dirty || email?.touched) && email?.errors?.['required']"
              class="text-start invalid-feedback"
            >
              Email is required.
            </div>
            <div
              *ngIf="email?.invalid && (email?.dirty || email?.touched) && email?.errors?.['email']"
              class="text-start invalid-feedback"
            >
              Email is invalid.
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput13">Designation</label>
            <input
              type="text"
              class="form-control form-control-alternative"
              id="exampleFormControlInput13"
              placeholder="Your designation"
              formControlName="designation"
              [class.is-invalid]="
                designation?.invalid &&
                (designation?.dirty || designation?.touched)
              "
              [class.is-valid]="designation?.valid"
            />
            <div
              *ngIf="designation?.invalid && (designation?.dirty || designation?.touched) && designation?.errors?.['required']"
              class="text-start invalid-feedback"
            >
              Designation is required.
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="exampleFormControlInput14">Company</label>
            <input
              type="text"
              class="form-control form-control-alternative"
              id="exampleFormControlInput14"
              placeholder="Your company"
              formControlName="company"
              [class.is-invalid]="
                company?.invalid && (company?.dirty || company?.touched)
              "
              [class.is-valid]="company?.valid"
            />
            <div
              *ngIf="company?.invalid && (company?.dirty || company?.touched) && company?.errors?.['required']"
              class="text-start invalid-feedback"
            >
              Company is required.
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="exampleFormControlSelect1"
              >How did you hear about us?</label
            >
            <select
              class="form-control form-control-alternative"
              id="exampleFormControlSelect1"
              formControlName="heard"
              [class.is-invalid]="
                heard?.invalid && (heard?.dirty || heard?.touched)
              "
              [class.is-valid]="heard?.valid"
            >
              <option [selected]="true" disabled value="1">
                --Choose Category--
              </option>
              <option>Social media</option>
              <option>LinkedIn</option>
              <option>Facebook</option>
              <option>Prefer not to say</option>
            </select>
            <div
              *ngIf="heard?.invalid && (heard?.dirty || heard?.touched) && heard?.errors?.['required']"
              class="text-start invalid-feedback"
            >
              This field is required.
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="exampleFormControlInput15">Subject</label>
            <input
              type="text"
              class="form-control form-control-alternative"
              id="exampleFormControlInput15"
              placeholder="Your subject"
              formControlName="subject"
              [class.is-invalid]="
                subject?.invalid && (subject?.dirty || subject?.touched)
              "
              [class.is-valid]="subject?.valid"
            />
            <div
              *ngIf="subject?.invalid && (subject?.dirty || subject?.touched) && subject?.errors?.['required']"
              class="text-start invalid-feedback"
            >
              Subject is required.
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="exampleFormControlInput16">Message</label>
            <textarea
              class="form-control form-control-alternative"
              id="exampleFormControlInput16"
              rows="3"
              placeholder="Please enter your message here"
              formControlName="message"
              [class.is-invalid]="
                message?.invalid && (message?.dirty || message?.touched)
              "
              [class.is-valid]="message?.valid"
              [maxlength]="maxChars"
            ></textarea>
            <div
              *ngIf="message?.invalid && (message?.dirty || message?.touched) && message?.errors?.['required']"
              class="text-start invalid-feedback"
            >
              Message is required.
            </div>
          </div>
        </div>

        <div class="col-12 text-right">
          <button type="submit"
           class="btn btn-primary btn-icon">
            Send
            <i class="fa-regular fa-paper-plane ml-2"></i>
          </button>
        </div>
      </form>
    </div>
  </div>
</section>
