import { Component, ViewEncapsulation } from '@angular/core';
import { FEATURES, LINGO } from 'src/app/shared/constants/navbar';
import { MyService } from 'src/app/services/myservice';
import { DeviceDetectorService } from 'ngx-device-detector';
import { eLLMoFAQS, FAQS } from 'src/app/shared/constants/home';
@Component({
    selector: 'app-about',
    templateUrl: './ellmo.component.html',
    styleUrls: ['../../../app.common.scss', './ellmo.component.scss'],
    encapsulation: ViewEncapsulation.Emulated // or ShadowDom
})
export class EllmoComponent {

    features = FEATURES;
    lingo = LINGO;
    ngOnInit(): void {
        this.myservice.setTitleAndMeta("eLLMo", "eLLMo is Enterprise Large Language Model Outcomes - It is a private LLM which works on your custom data within the boundaries of the company. Use LLM to integrate with various tools such as Confluence, Sharepoint, Jira, GSuite and several others. Q&A on any type of document.", "Q&A, chatbot, conversational AI, ellmo, confluence, sharepoint, jira, gsuite, files, file management, role based access control, rbac, enterprises, sso, rag, knowledge graphs, agents", "ellmo");
    }

    content: Content = {
        'experience-ai': {
            title: 'Experience AI',
            lead: 'Enhance customer, agent, and employee experiences.',
            description: 'Experience AI integrates with existing systems to provide personalized interactions and automate routine tasks, improving efficiency and satisfaction.',
            details: 'Our solutions are designed to make interactions smoother and more meaningful, whether it\'s through custom chatbots, personalized content, or automated workflows.',
            buttonText: 'Explore More',
            imageUrl: '../../../../assets/images/experience-ai.webp'
        },
        'search-ai': {
            title: 'Search AI',
            lead: 'Unlock the power of data with intelligent search capabilities.',
            description: 'Search AI enables businesses to quickly find and retrieve information across vast datasets, improving efficiency and decision-making.',
            details: 'Whether it\'s internal knowledge bases or customer-facing search features, our Search AI solutions provide accurate, context-aware results that meet your specific needs.',
            buttonText: 'Discover More',
            imageUrl: '../../../../assets/images/search-ai.webp'
        },
        'conversational-ai': {
            title: 'Generative AI',
            lead: 'Engage with customers in real-time across channels',
            description: 'Our Conversational AI products provide seamless interactions with customers, powered by cutting-edge language models that understand and respond in a human-like manner.',
            details: 'These solutions are designed to enhance customer engagement, reduce response times, and improve overall satisfaction by offering 24/7 support.',
            buttonText: 'Learn More',
            imageUrl: '../../../../assets/images/conversational-ai.webp'
        },
    };

    getContent(tab: string): TabContent {
        return this.content[tab];
    }
    anchorPlacement = 'bottom-bottom';

    activeProductIndex = 0;
    activeNav = 1;
    responsiveFade = 'fade-left';
    activeGif: string | undefined = '';
    faqs = eLLMoFAQS;
    constructor(
        private myservice: MyService,
        deviceService: DeviceDetectorService,

    ) {
        if (deviceService.isMobile()) {
            this.anchorPlacement = 'top-bottom';
            this.responsiveFade = 'fade-up';
        }
        if (deviceService.isDesktop()) {
            this.anchorPlacement = 'top-bottom';
            this.responsiveFade = 'fade-up';
        }
    }
    data = [
        'search with ai',
        'llm based q&a model',
        'llm based paas ai',
        'large language model based api',
        'llm based generative ai search',
        'generative ai models',
        'small language model',
        'small LLM',
        'llm vector search',
        'llm based entity recognition with prompt',
        'prompt sentiment analysis with generative ai',
        'best large language model ai',
        'understanding large language models',
        'large language models overview',
        'large language models gpt',
        'private gpt',
        'private llm',
        'train your own model',
        'fine-tuning large language models',
        'train your own language model',
        'ai for operations',
        'ai in enterprise',
        'ai at workplace',
        'llm/gpt llm for workplace',
        'llm/gpt for enterprise',
        'llm/gpt for enterprise',
        'llm/gpt for organization',
        'ai for language',
        'language gpt',
        'GPT in other language',
        'gpt in native language',
        'search audio/video with llm'
    ];
    getWordsByIndex(index: number): string {
        const startIndex = index * 2;
        const endIndex = startIndex + 1;

        if (startIndex < 0 || endIndex >= this.data.length) {
            return "Invalid index";
        }

        const word1 = this.data[startIndex];
        const word2 = this.data[endIndex];

        return `${word1} ${word2}`;
    }

    getKeys(obj: any): string[] {
        return Object.keys(obj);
    }

    selectedTab: string = 'experience-ai';
    selectTab(tab: string) {
        this.selectedTab = tab;
    }

}

interface TabContent {
    title: string;
    lead: string;
    description: string;
    details: string;
    buttonText: string;
    imageUrl: string;
}

// Define the type for the content object
interface Content {
    [key: string]: TabContent;
}
