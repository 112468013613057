import { Component } from '@angular/core';
import { MyService } from 'src/app/services/myservice';

@Component({
  selector: 'app-refund-policy',
  templateUrl: './refund-policy.component.html',
  styleUrls: [ './refund-policy.component.scss']
})
export class RefundPolicyComponent {
    
  constructor(private myservice:MyService) {
      this.myservice.setTitleAndMeta("Refund Policy","Our Refund policy and Return policy","terms and conditions, privacy poicy, payment terms, return policy, refund policy, T&C, cookie policy, GDPR, data collection, cookies, storage policy, EULA, legal aspects, law","refund-policy");
  }
}
