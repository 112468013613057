import {
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MyService } from 'src/app/services/myservice';

import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

declare var ZFWidget: any;


@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    standalone: true,
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NgbAlertModule],
    styleUrls: ['../../../app.common.scss', './pricing.component.scss'],
})
export class PricingComponent {
    isPageAccess: boolean = false;
    @ViewChild('content', { static: true }) content!: ElementRef;
    @ViewChild('content2', { static: true }) content2!: ElementRef;

    closeModal() {
        this.modalService.dismissAll();
    }



    pricingTableComponentOptions = {
        id: 'zf-widget-root-id',
        product_id: '2-29256296810424ed80a796387ad0102c7ffbe19bd713ef4d248ade52d92e5216d123266df535a4b758bcad6fd428a6fd47309c4d8e4d2cb398cd2df8bf2546d7',
        template: 'elegant_pro',
        most_popular_plan: 'GEN-PRO-001',
        is_group_by_frequency: false,
        isFrequencyDropdown: false,
        isCurrencyDropdown: false,
        can_show_plan_freq: true,
        pricebooks: [
            {
                pricebook_id: '1814191000000096279',
                currency_code: 'USD',
                currency_symbol: '$',
                plans: [
                    {
                        plan_code: 'GEN-STR-001',
                        url: 'https://subscriptions.zoho.in/subscribe/3be170f9ee05ea4ba3c666b3ffcf60696293d24b98ed987c68ca5236ee0df09a/GEN-STR-001',
                        recurring_price: '69',
                        recurring_price_formatted: '$69.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    },
                    {
                        plan_code: 'GEN-PRO-001',
                        url: 'https://subscriptions.zoho.in/subscribe/3be170f9ee05ea4ba3c666b3ffcf6069fbd811e2fe7194828135fa4eb7de82b8/GEN-PRO-001',
                        recurring_price: '99',
                        recurring_price_formatted: '$99.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    },
                    {
                        plan_code: 'GEN-ENT-001',
                        url: 'https://subscriptions.zoho.in/subscribe/3be170f9ee05ea4ba3c666b3ffcf60697259d88465260ec25913ed861ffa6560/GEN-ENT-001',
                        recurring_price: '2499',
                        recurring_price_formatted: '$2,499.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    },
                    {
                        plan_code: 'GEN-PAY-002',
                        url: 'https://subscriptions.zoho.in/subscribe/3be170f9ee05ea4ba3c666b3ffcf60698e380d644e97cf3a9c1d5839f3ffda88/GEN-PAY-002',
                        recurring_price: '0',
                        recurring_price_formatted: '$0.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    }, {
                        plan_code: 'GEN-API-001',
                        url: 'https://billing.genailia.com/subscribe/47309c4d8e4d2cb31d561bca3ef56c9588adfcede525ed1adbde1ccf1c7a189d/GEN-API-001',
                        recurring_price: '149',
                        recurring_price_formatted: '$149.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    },

                ]
            },
            {
                pricebook_id: '1814191000000000261',
                currency_code: 'INR',
                currency_symbol: 'Rs.',
                plans: [
                    {
                        plan_code: 'GEN-STR-001',
                        url: 'https://subscriptions.zoho.in/subscribe/47309c4d8e4d2cb31d561bca3ef56c9588adfcede525ed1adbde1ccf1c7a189d/GEN-STR-001',
                        recurring_price: '5699',
                        recurring_price_formatted: 'Rs.5,699.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    },
                    {
                        plan_code: 'GEN-PRO-001',
                        url: 'https://subscriptions.zoho.in/subscribe/47309c4d8e4d2cb31d561bca3ef56c9588adfcede525ed1adbde1ccf1c7a189d/GEN-PRO-001',
                        recurring_price: '8299',
                        recurring_price_formatted: 'Rs.8,299.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    },
                    {
                        plan_code: 'GEN-ENT-001',
                        url: 'https://subscriptions.zoho.in/subscribe/47309c4d8e4d2cb31d561bca3ef56c9588adfcede525ed1adbde1ccf1c7a189d/GEN-ENT-001',
                        recurring_price: '199999',
                        recurring_price_formatted: 'Rs.199,999.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    },
                    {
                        plan_code: 'GEN-PAY-002',
                        url: 'https://subscriptions.zoho.in/subscribe/47309c4d8e4d2cb31d561bca3ef56c9588adfcede525ed1adbde1ccf1c7a189d/GEN-PAY-002',
                        recurring_price: '0',
                        recurring_price_formatted: 'Rs.0.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    },
                    {
                        plan_code: 'GEN-API-001',
                        url: 'https://subscriptions.zoho.in/subscribe/47309c4d8e4d2cb31d561bca3ef56c9588adfcede525ed1adbde1ccf1c7a189d/GEN-PAY-002',
                        recurring_price: '11999',
                        recurring_price_formatted: 'Rs.11,999.00',
                        hp_settings_id: '1814191000000000291',
                        button_action_type: 'hp',
                        button_action_value: ''
                    }

                ]
            }


        ],
        group_options: [

        ],
        plans: [
            {
                plan_code: 'GEN-STR-001',
                selectedAddons: [

                ]
            },
            {
                plan_code: 'GEN-PRO-001',
                selectedAddons: [

                ]
            },
            {
                plan_code: 'GEN-ENT-001',
                selectedAddons: [

                ]
            },
            {
                plan_code: 'GEN-PAY-002',
                selectedAddons: [
                    {
                        addon_code: 'GEN-PAY-001',
                        addon_quantity: '1'
                    }

                ]
            },
            {
                plan_code: 'GEN-API-001',
                selectedAddons: [


                ]
            },

        ],
        theme: { color: '#172B4D', theme_color_light: '' },
        button_text: 'Subscribe',
        product_url: 'https://subscriptions.zoho.in',
        price_caption: '',
        language_code: 'en',
        open_inSameTab: false
    };
    ngOnInit(): void {
        this.activatedRoute.data.subscribe((response: any) => {
            this.isPageAccess = response.access;
        });
        this.myservice.setTitleAndMeta("Pricing", "Explore pricing for all services. Subscribe to a plan and get started today for as low as $9", "pricing, plans, subscriptions, creator, enterprise plans, starter, pay as you go, api, saas, paas, invoices", "pricing");
    }


    constructor(
        private modalService: NgbModal,
        config: NgbModalConfig,
        private activatedRoute: ActivatedRoute,
        private myservice: MyService
    ) {

        config.backdrop = 'static';
        config.keyboard = false;
        config.centered = true;
        config.size = 'lg';
        config.windowClass = 'dark-modal';

    }

    openLink(link: any) {
        // this.modalService.dismissAll();
        window.open(link, '_blank');
    }

    open() {
        this.modalService.open(this.content);
    }

    openFree() {
        this.modalService.open(this.content2);
    }

    ngAfterViewInit() {
        this.open();
        ZFWidget.init('zf-pricing-table', this.pricingTableComponentOptions);

    }
}
