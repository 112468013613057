import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { environment } from 'src/environments/environment';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-media',
    templateUrl: './media.component.html',
    styleUrls: ['../../../../app.common.scss', './media.component.scss'],
})
export class MediaComponent implements OnInit {
    baseUrl: string = environment.baseUrl;

    urls: string[] = [];
    displayedUrls: string[] = [];
    private pageSize = 4;  // Number of videos to display at a time
    private pageIndex = 0; // Current page index

    constructor(
        private myservice: MyService,
        private authService: AuthService, private http: HttpClient, private router: Router) {
    }

    navigateTo(url: string, newTab: boolean): void {
        if (url.indexOf("refer") > 0 || url.indexOf("subscribe") > 0) {
            newTab = false;
        }

        if (newTab) {
            window.open(
                url,
                '_blank'
            );
        } else {
            this.router.navigate([url]);
        }
    }

    async ngOnInit() {
        try {
            const token = await this.authService.fetchToken().toPromise();
            const headers = { Authorization: 'Bearer ' + token };
            const data = await this.http.get<any[]>(`${this.baseUrl}/ytvideos?channelId=UCEwveJq3X7qyCIx0-9wzyKA`, { headers }).toPromise();
            this.urls = data!.map(item => item.url.replace('watch?v=', 'embed/'));
            this.addMoreVideos();
            console.log(this.urls); // Now this should log the actual URLs
        } catch (error) {
            console.error('Error fetching URLs:', error);
        }
        this.myservice.setTitleAndMeta("Media Library", "View videos from our YouTube channel featuring the latest updates, tutorials, and insights from Genailia.", "media, ai videos, ai podcasts, youtube, live, library, videos, knowledge, share", "media");
    }


    addMoreVideos(): void {
        const moreUrls = this.urls.slice(this.pageIndex * this.pageSize, (this.pageIndex + 1) * this.pageSize);
        this.displayedUrls = [...this.displayedUrls, ...moreUrls];
        this.pageIndex++;
    }

    hasMoreVideos(): boolean {
        return this.pageIndex * this.pageSize < this.urls.length;
    }
}