<div class="container py-4 commn">
    <div class="commn-heading mt-4">
        <div class="commn-heading-hyphon"></div>
        <div class="commn-heading-text">
            <span>CASE</span>
            <span class="commn-heading-ai-text"> Studies</span>
        </div>
    </div>
	    <h3>Case Studies and Use Cases coming soon..</h3>
    
</div>