import { Component } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MyService } from 'src/app/services/myservice';
@Component({
    selector: 'app-press',
    templateUrl: './press.component.html',
    styleUrls: ['../../../../app.common.scss', './press.component.scss'],
})
export class PressComponent {
    constructor(
        private myservice: MyService,
        deviceService: DeviceDetectorService
    ) {
        if (deviceService.isMobile()) {
        }

    }

    public newsLinks: { url: string; img: string }[] = [
        { url: 'https://t-hub.co/t-hub-and-hexagon-selects-12-startups-for-lab32-cohort-driving-innovation-in-ai-and-digital-reality/', img: 'news1.webp' },
        { url: 'https://timesofindia.indiatimes.com/city/hyderabad/t-hub-hexagon-roll-out-new-lab32-cohort/articleshow/107737509.cms', img: 'news2.webp' },
        { url: 'https://www.youtube.com/watch?v=hGkUYZISBT8', img: 'news3.webp' },
        { url: 'https://themanifest.com/company/genz-technologies-private', img: 'news4.webp' },
        { url: 'https://www.einpresswire.com/article/632169104/genzers-ai-revolutionizing-ai-chatbots-and-translation-services-in-india-ai-services-for-make-in-india-and-the-world', img: 'news5.webp' },
        { url: 'https://fox2now.com/business/press-releases/ein-presswire/632169104/genzers-ai-revolutionizing-ai-chatbots-and-translation-services-in-india-ai-services-for-make-in-india-and-the-world/', img: 'news6.webp' },
        { url: 'https://www.indiamorningtimes.com/article/632169104-genzers-ai-revolutionizing-ai-chatbots-and-translation-services-in-india-ai-services-for-make-in-india-and-the-world', img: 'news7.webp' },
        { url: 'https://www.entrepreneurshipreporter.com/article/632169104-genzers-ai-revolutionizing-ai-chatbots-and-translation-services-in-india-ai-services-for-make-in-india-and-the-world', img: 'news8.webp' },
        { url: 'https://www.crunchbase.com/organization/genzers-technologies', img: 'news9.webp' },
        { url: 'https://www.f6s.com/company/genzers', img: 'news10.webp' },
        { url: 'https://aws.amazon.com/startups/showcase/startup-details/f78a3f92-aaf7-4f8f-a124-3c573751c9f3?lang=en-US', img: 'news11.webp' },
        { url: 'https://yourstory.com/companies/genz-technologies', img: 'news12.webp' },
    ];

    ngOnInit(): void {
        this.randomizeNews();
        this.myservice.setTitleAndMeta("Press Coverage", "Catch up on the latest press coverage, including updates, product launches, and key news in the world of Artificial Intelligence.", "Press, news coverage, press coverage, articles about genailia, youtube channel coverage", "press");
    }

    randomizeNews(): void {
        this.newsLinks.sort(() => Math.random() - 0.5);
    }


}
