<div class="jumbotron text-center  ">
    
        <h1 class="display-1">Get a Demo</h1>
        <p class="h5">Book time for a demo of Genailia from one of the founding </p>
        <p class="h5">team members and evaluate if it is a right fit for your needs.</p>

    
    
</div>
<div class="container demo py-4 ">
    
    <!-- Calendly inline widget begin -->
    <div class="calendly-inline-widget custom-calendly" data-url="https://calendly.com/genailia"></div>
    <!-- Calendly inline widget end -->
</div>