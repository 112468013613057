import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { AuthService } from 'src/app/shared/services/auth.service';
import { OrderService } from 'src/app/shared/services/order.service';
import { environment } from 'src/environments/environment';
import { CommonModule } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SOCIAL_NETWORKS } from 'src/app/shared/constants/home';
import { MyService } from 'src/app/services/myservice';

@Component({
  selector: 'app-e-book',
  templateUrl: './e-book.component.html',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  styleUrls: ['../../../../app.common.scss', './e-book.component.scss'],
})
export class EBookComponent {
  contactForm!: FormGroup;
  isLoading: boolean = false;
  isContactFormSubmitted = false;
  error: any;
  success: any;
  siteKey = environment.siteKey;
  maxChars: number = 500;
  selectedValue: string = '';

  anchorPlacement = 'bottom-bottom';
  responsiveFade = 'fade-left';

  socialNetworks = SOCIAL_NETWORKS;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private orderService: OrderService,
    private myservice: MyService,
    deviceService: DeviceDetectorService
  ) {
    if (deviceService.isMobile()) {
      this.anchorPlacement = 'top-bottom';
      this.responsiveFade = 'fade-up';
    }
  }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      // name: ['', [Validators.required, Validators.minLength(2)]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      designation: ['', [Validators.required]],
      company: ['', [Validators.required]],
      subject: ['', Validators.required],
      //      'howhear':['', [Validators.required]],
      message: ['', [Validators.required]],
      //      'recaptcha': ['', [Validators.required]]
      heard:['',[Validators.required]],

    });
  }

  onSubmit() {
    if (this.contactForm.invalid) return;

    console.log(this.contactForm.value)

    this.isLoading = true;
    this.error = null;
    this.contactForm.disable();
    this.orderService.contactUs(this.contactForm.value).subscribe(
      (res: any) => {
        if (res && res.status) {
          this.success = 'Your message has been submitted successfully.';
          this.isLoading = false;
          this.contactForm.enable();
          this.contactForm.reset();
          this.isContactFormSubmitted = true;
        }
      },
      (err) => {
        this.error = err && err.message ? err.message : 'Contact us failed.';
        this.isLoading = false;
        this.contactForm.enable();
      }
    );
  }

  get name() {
    return this.contactForm.get('name');
  }
  get email() {
    return this.contactForm.get('email');
  }
  get designation() {
    return this.contactForm.get('designation');
  }
  get company() {
    return this.contactForm.get('company');
  }
  get subject() {
    return this.contactForm.get('subject');
  }
  get howhear() {
    return this.contactForm.get('howhear');
  }
  get message() {
    return this.contactForm.get('message');
  }
  get heard(){
    return this.contactForm.get('heard')
  }

  handleSuccess(event: any) {}
}
