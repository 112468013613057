import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class OrderService {

    private baseUrl: string = environment.baseUrl;

    private pricing = [
        {
            type: 'basic',
            amount: 0,
            actualAmount: 1999,
            duration: null,
            validity: 'Valid for 3 weeks',
            buttonText: 'Sign up for Basic',
            permissions: [
                { key: '1', value: '300 words per month (inclusive of the below' },
                { key: '2', value: 'Speech to text Outputs' },
                { key: '3', value: 'Text to Speech Inputs' },
                { key: '4', value: 'Transcription Outputs' },
                { key: '5', value: 'Translation Inputs' },
                { key: '6', value: '3 weeks to upgrades' },
            ]
        },
        {
            type: 'silver',
            amount: 4999,
            actualAmount: 9999,
            duration: 'Every Month',
            validity: '7 day free trial',
            buttonText: 'Sign up for Silver',
            permissions: [
                { key: '1', value: 'All benefits of Free Membership' },
                { key: '2', value: '3000 words per month (WPM Inclusive of the below)' },
                { key: '3', value: 'Video Transcriptions - Output Word count against 3000 WPM' },
                { key: '4', value: 'OCR Outputs' },
                { key: '5', value: 'ChatGPT Outputs' },
            ]
        },
        {
            type: 'gold',
            amount: 14999,
            actualAmount: 24999,
            duration: 'Every Month',
            validity: '7 day free trial',
            buttonText: 'Sign up for Gold',
            permissions: [
                { key: '1', value: 'All benefits of Silver Membership' },
                { key: '2', value: 'Pay as you go Plans' },
                { key: '3', value: 'Priority to Issues raised' },
                { key: '4', value: 'Customized Plans' },
                { key: '5', value: 'Contact us for Pricing' },
            ]
        }
    ];

    constructor(
        private http: HttpClient,
        private authService: AuthService
    ) { }

    createOrder(body: any): Observable<any> {
        const headers = {
            "Authorization": "Bearer " + this.authService.getToken()
        };
        return this.http.post(`${this.baseUrl}/payment/createorder`, body, { headers: headers });
    }

    paymentSuccess(body: any): Observable<any> {
        const headers = {
            "Authorization": "Bearer " + this.authService.getToken()
        };
        return this.http.post(`${this.baseUrl}/payment/paymentsuccess`, body, { headers: headers });
    }

    paymentFailure(body: any): Observable<any> {
        const headers = {
            "Authorization": "Bearer " + this.authService.getToken()
        };
        return this.http.post(`${this.baseUrl}/payment/paymentfailure`, body, { headers: headers });
    }

    getPricing(type = ''): Observable<any> {
        if (type !== '') {
            const index = this.pricing.findIndex((f: any) => f.type == type);
            return of(this.pricing[index]);
        } else {
            return of(this.pricing);
        }
    }

    getQuotation(body: any): Observable<any> {
        const data = new FormData();
        data.append('name', body.name);
        data.append('source', 'Genzers');
        data.append('email', body.email);
        data.append('designation', body.designation);
        data.append('company', body.company);
        data.append('productsAsk', body.productsAsk);

        data.append('message', body.message);
        return this.http.post(`${this.baseUrl}/misc/getquote`, data);
        // ask karthik for rest call
    }

    contactUs(body: any): Observable<any> {
        const data = {
            name: body.name,
            email: body.email,
            designation: body.designation,
            company: body.company,
            subject: body.subject,
            message: body.message,
            heard: body.heard
        };

        return this.http.post(`${this.baseUrl}/misc/contactus`, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });



    }
}
