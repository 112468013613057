import { Injectable } from '@angular/core';
import { EMPTY, Observable, mergeMap, of } from 'rxjs';
import { map, share } from "rxjs/operators";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl: string = environment.baseUrl;

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  signUp(userData: any): Observable<any> {
    const data = {
      firstname: userData.firstName,
      lastname: userData.lastName,
      email: userData.email,
      password: userData.password,
      organization: userData.organization,
      organization_designation: userData.designation,
      phone_number: userData.phone,
      department:userData.department
    };
    return this.http.post(`${this.baseUrl}/auth/register`, data);
  }

  login(email: string, password: string): Observable<any> {
    return this.http.post(
      `${this.baseUrl}/auth/authenticate`,
      { email: email, password: password }
    ).pipe(
      mergeMap((res: any) => {
        if (res && res.token) {
          this.storeToken(res.token);
          return this.refreshToken()
        }
        return EMPTY;
      })
    );
  }



  onlyGetData(email:string):Observable<any>{
    return this.http.post(`${this.baseUrl}/auth/any`,{email:email});
  }
  refreshToken(): Observable<any> {
    const headers = {
      "Authorization": "Bearer " + this.getToken()
    }
    return this.http.post(`${this.baseUrl}/auth/refreshtokenui`, {}, { headers: headers }).pipe(
      share()
    );
  }

  isTokenExpired(): any {
    const token: any = this.getToken();
    if (token && token !== null && token !== "") {
      const expiry = (JSON.parse(window.atob(token.split('.')[1]))).exp;
      if (expiry) {
        return expiry * 1000 < Date.now();
      }
    } else {
      return true;
    }
  }

  storeToken(token: string) {
    localStorage.setItem('token', token);
  }

  getToken(){
    return localStorage.getItem('token')
  }


 generateRandomWord() {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyz';
    const length = 5;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}
  private tokenUrl = 'https://api.genailia.com/api/v2/generateSLToken?###=true';
  fetchToken(): Observable<string> {
      const tokenUrl1  = this.tokenUrl.replace("###",this.generateRandomWord());
    return this.http.get(tokenUrl1, { responseType: 'text' });
  }

  isUserLoggedIn(): boolean {
    if (this.getToken() != null) {
      return true;
    }
    return false;
  }

  logout() {
    this.http.get(`${this.baseUrl}/auth/logout`).pipe(map(res => {
      localStorage.removeItem('token');
      this.router.navigateByUrl('/login');
    })).subscribe();
  }

  getMembershipStatus(slug: string): Observable<any> {
    const data = new URLSearchParams();
    data.append('pageSlug', slug);

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      "Authorization": "Bearer " + this.getToken()
    }
    return this.http.post(`${this.baseUrl}/auth/validate`, data, { headers: headers });
  }

  forgotPassword(email: string): Observable<any> {
    const data = new FormData();
    data.append('email', email);

    return this.http.post(`${this.baseUrl}/auth/forgot-password`, data);
  }

  getMember(email: string): Observable<any> {
    const data = new FormData();
    data.append('email', email);

    const headers = {
      "Authorization": "Bearer " + this.getToken()
    }
    return this.http.post(`${this.baseUrl}/auth/member`, data, { headers: headers });
  }

  getEmail(): any {
    const token = this.getToken();
    if (token) {
      const parsedToken = this.parseJwt(token)['sub'];
      return parsedToken;
    }
  }

  parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  cmpGet(emailId:string,recipientId:string,mailerId:string,type:string):Observable<any>{
    console.log("Calling mail capturing for email campaign");
    return this.http.get(`https://api.genailia.com/api/v2/misc/track?emailId=${emailId}&recipientId=${recipientId}&mailerId=${mailerId}&type=${type}`)
  }
}
