import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IRssItem, NewsRss } from '../models/rss';
import { AuthService } from 'src/app/shared/services/auth.service';
import { delay, of, switchMap } from 'rxjs';

export interface Post {
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  baseUrl: string = environment.baseUrl;
  private postsSubject$: BehaviorSubject<IRssItem[]> = new BehaviorSubject<
    IRssItem[]
  >([]);

  constructor(private http: HttpClient, private authService: AuthService) {}

  getPosts(): Observable<any> {
    const body = {};
    return this.authService.fetchToken().pipe(
      switchMap((token) => {
        const headers = {
          Authorization: 'Bearer ' + token,
        };
        return this.http.post(`${this.baseUrl}/misc/blog`, body, {
          headers: headers,
          responseType: 'text',
        });
      })
    );
  }
  

  getBlogPosts(
    guid?: string,
    includeContent?: boolean,
    category?: string,
    numberOfPosts?: number,
    sortOrder?: string,
    excludeUUID?: string
  ): Observable<any> {
    let params = new HttpParams();

    if (guid) {
      params = params.set('guid', guid);
    }
    if (includeContent !== undefined) {
      params = params.set('includeContent', includeContent.toString());
    }
    if (category) {
      params = params.set('category', category);
    }
    if (numberOfPosts) {
      params = params.set('numberOfPosts', numberOfPosts.toString());
    }
    if (sortOrder) {
      params = params.set('sortOrder', sortOrder);
    }
    if (excludeUUID) {
      params = params.set('excludeUUID', excludeUUID);
    }

    return this.authService.fetchToken().pipe(
        switchMap((token) => {
          const headers = {
            Authorization: 'Bearer ' + token,
          };
          return this.http.get(`${this.baseUrl}/blog?section=blog`, {params,headers});
        })
      );
  }

  setPosts(items: IRssItem[]) {
    this.postsSubject$.next(items);
  }

  getAllPosts() {
    return this.postsSubject$.asObservable();
  }

  getSlug(slug: string): string {
    return slug.substring(slug.lastIndexOf('/') + 1);
  }
}
